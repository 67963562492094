import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import apiService from "./services/apiService";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: {
      ad: "Advertising",
      readMore: "Read more",
      half_time: "Half Time",
      mintute: "min.",
      period_1: "1ST HALF",
      period_2: "2ND HALF",
      or: "or",
      signUpTxt: "Sign up to continue",
      signInTxt: "Sign in",
      resultsPage: {
        title: "Results",
      },
      allSports: "All sports",
      line: "Line",
      predictions: {
        bottomTextTitle:
          "Free Sports Predictions for Today, Tomorrow, and Accumulators.",
        bottomTextDescription:
          "On Capperauditor, you will find over {{count}} predictions on 14+ sports, up-to-date predictions, accumulators, and much more. Connect with like-minded people, follow experts, and enjoy an active sports community.",
      },
      gameDetail: {
        overView: "",
        avgCoef: "",
        avgPrice: "",
        predictions: "",
        analytics: "",
        predictionOverview: {
          title: "",
          before: "",
          live: "",
          single: "",
          express: "",
          beforeHint: "",
          liveHint: "",
          singleHint: "",
          expressHint: "",
          total: ""
        }
      },
      rewards: {
        metaTitle: "Achievements",
        metaDescription: "Be active and gain new achievements.",
        modalTitle: "A big update: Launching the achievement system",
        modalSubTitle:
          "We are very pleased to announce that we have launched an achievement system! Now your efforts on our website will be even more rewarded. Achievements not only make the game more interesting, but also give you additional CAP coins for your activity. Whether it's an accurate predictions, regular coin collection, or an increase in the number of follower, your every step will be rewarded.",
        close: "Close",
        open: "Go to achievements",
        rewardCoinsTab: "Awards",
        timeTo: "Time remaining until the end of the tournament",
        level: "LEVEL",
        progress: "PROGRESS",
        award: "AWARD",
        end: "Ended",
        openRewards: "Open Achievements",
        noRewardTitle: "You don't have any achievements yet",
        noRewardSubTitle:
          "Be active, and your first achievements will appear here soon.",
        noRewardUserTitle: " has no achievements yet",
        noRewardUserSubTitle:
          "As soon as the user achieves their first results, they will appear here immediately.",
        showAll: "Show all",
        showAllRewards: "Show all achievements",
        rewardCompleted: "Achievement received",
        rewardWait: "Coming soon",
        rewardWaitTooltip: "An exclusive achievement will be available soon",
        rewardOver: "Completed",
        rewardOverTooltip:
          "The tournament is over, but don't be discouraged, new exclusive achievements will be coming soon.",
        levelsCompleted: "You have completed all levels of this achievement.",
        levelsCompletedExclusive:
          "The exclusive achievement has already been added to your collection of achievements.",
        claim: "Claim your reward",
        analyst: "Sports analyst",
        collector: "Collector",
        influencer: "Influencer",
        partner: "Reliable partner",
        exclusive: "Exclusive achievements",
        continueLbl: "Continue",
        reward: "Achievements",
        titleDesc: "Be active and gain new achievements.",
        rewardTitle: "You have achieved level {{level}} of the ",
        rewardTitleExclusive: "You have earned the exclusive achievement ",
        rewardDescription: "Your account has been credited with ",
        capCoins: "CAP coins.",
        motivation1:
          "We follow your progress and are confident that your efforts will bring impressive results.",
        motivation2:
          "You are cooler than 50% of users. Keep up the good work 🔥.",
        motivation3:
          "Your pace is impressive. You have already overtaken 65% of users. 👏",
        motivation4:
          "You're already in the top 10%! Just a little more to reach the top. 💪",
        motivation5:
          "You're a true leader! You're in the top 1% of the best predictors. 🥇",
      },
      noGamePage: {
        title: "Game over or page not found",
        text: "Oops! It looks like the game you're searching for has either ended or doesn't exist. Please head to the sports line to check out other events.",
        btn: "Go to Sports Line",
      },
      emptyPageText: {
        noFollowingPredictionsTitle: "No Predictions Yet",
        noFollowingPredictionsText:
          "Here you will see predictions from the users you follow when they are published.",
        noFilteredPredictionsTitle: "No Matching Predictions",
        noFilteredPredictionsText:
          "Unfortunately, there are no predictions found based on your selected filters. Please adjust your filtering parameters or try again later.",
      },
      homePageSigninPage: {
        SignIn: "Sign in to Capperauditor",
        isGoogleBtn: "Continue with Google",
        isFabookBtn: "Continue with Facebook",
        or: "or",
        emailorusername: "Your email or username",
        EnteremailPlaceHolder: "Enter your email or username...",
        Continue: "Continue",
        subLine1: "Don't have an account?",
        subLine2: "Sign Up",
      },
      mainHomePage: {
        Free: "Free",
        publish: "publish",
        mainTitle: "sports predictions and win real prizes",
        mainSubTitle:
          "A social network where everyone can make free sports predictions, track statistics, earn coins, and exchange them for real prizes",
        defaultCoinTitle: " coins bonus for all new users",
        FreeRegistration: "Free registration",
        watchVideoBtn: "Watch the video (1 min)",
        counter1: "Predictors joined",
        counter2: "Predictions made",
        counter3: "Coins earned",
        counter4: "Coins collected",
        counter5: "Average coefficient of predictions",
        sportsKnowledgeIntroTitle:
          "Three simple steps to start monetizing your sports knowledge",
        sportsKnowledgeIntroSubTitle:
          "Make sports predictions, get coins, and exchange them for real prizes.",
        Step: "Step",
        step1Title: "Publish sports predictions",
        step1SubTitle:
          "Publish your predictions for your favorite sports for free, both before the event and in real time. You have access to a sports lineup that includes 14 sports and thousands of available events, among which you will definitely find those that interest you",
        step1Btn: "Make a prediction",
        step2Title: "Get virtual coins",
        step2EarnTab: "Earn coins",
        step2CollectTab: "Collect coins",
        step3Title: "Exchange your coins for real prizes",
        step3SubTitle:
          "You can exchange earned and collected coins for real prizes in our store",
        step3Btn: "Go to the store",
        AndMoreTitle: "And much more...",
        addMoreItems1Title: "Sports line for 14",
        addMoreItems1Title2: "sports",
        addMoreItems1subTitle: "A wide range of sports events",
        addMoreItems2Title: "Real-time predictions",
        addMoreItems3Title: "A large community of of predictors",
        addMoreItems4Title:
          "Follow the predictions of your friends and experts",
        addMoreItems5Title: "Detailed statistics of predictions",
        addMoreItems5SubTitle:
          "A wide range of statistics for your predictions",
        addMoreItems6Title: "Share your predictions on social media",
        popularPredictionTitle: "The largest list of sports predictions",
        popularPredictionSubTitle:
          "See predictions from other users and experts",
        getBonusTitle1: "Capperauditor - Sports Social Network: Sports Predictions, Statistics, and Sports Community.",
        getBonusTitle2: "CAP coins in your account",
        getBonusSubTitle:
          "We can't wait to see what you will achieve with Capperauditor. That is why we have prepared a cool bonus of 1000 CAP coins for you. Register right now! Your bonus is already waiting for you!",
        getBonusBtn: "Get a bonus",
        FrequentlyQuestions: "Frequently asked questions",
        accodianTitle1: "For whom is capperauditor?",
        accodianSubTitle1: "The platform is ideal for the following users:",
        accodian1SubTitle1:
          " 1. Sports analysts: they can use Capperauditor to track the statistics of their predictions and increase their audience.",
        accodian1SubTitle2:
          "2. Gamblers who bet on sports for a living: with Capperauditor, they can effectively track their betting statistics and improve their betting strategies.",
        accodian1SubTitle3:
          "3. Amateurs who make sports forecasts but do not want to spend their own money: Capperauditor allows them to enjoy predicting without financial risk.",
        accodianTitle2: "Is Capperauditor a bookmaker?",
        accodianSubTitle2:
          "No, it's a social network where you can post and share your own predictions and view other users' predictions.",
        accodianTitle3: "Can I place sports bets on the website?",
        accodianSubTitle3: "No, you can only make free predictions.",
        accodianTitle4: "Is the platform free?",
        accodianSubTitle4: "Yes, all the tools on the site are free.",
        accodianTitle5: "What are CAP coins?",
        accodianSubTitle5: "CAP coins are the virtual currency of our website.",
        accodianTitle6: "Do I need to buy CAP coins?",
        accodian6SubTitle1: "No, you will receive a bonus of ",
        accodian6SubTitle2:
          " CAP coins after registration. Then you can collect free coins every day.",
        accodianTitle7:
          "Is there a possibility to win money or prizes on the website?",
        accodianSubTitle7:
          "Thus, the more successful your predictions are, the more CAP coins you can get, which can then be exchanged for real prizes in our ",
        accodianSubTitle8: "store.",
        ShowMorePredictions: "Show more predictions",
        reviewTitle1: "Hundreds of people love",
        reviewTitle2: "Capperauditor",
        TrustpilotScore: "Trustpilot score",
        TrustpilotScoreSubTitle: "Independent consumer rating",
        cookieTitle:
          "We use cookies for essential website functions and to better understand how you use our site, so we can create the best possible experience for you ❤. By using this website you agree to our cookie policy",
        CookiePolicyTitle: "Cookie Policy",
        freeRegistrationEmail: "Sign up via email",
        or: "OR",
        gotItBtn: "Ok, got it",
      },
      tooltip: {
        Mustcontainatleast: "Must contain at least:",
        characters: "12 characters",
        lowercasecharacter: "1 lower case character",
        uppercasecharacter: "1 upper case character",
        number: "1 number",
        specialcharacter: "1 special character ",
      },
      SignIn: {
        tabTitle: "Log in to Capperauditor",
        SignintoCapperauditor: "Log In to Capperauditor",
        ContinuewithGoogle: "Continue with Google",
        ContinuewithFacebook: "Continue with Facebook",
        or: "or",
        Youremailorusername: "Your email or username",
        Enteryouremailusername: "Enter your email or username",
        Donthaveaccount: "Don't have an account?",
        SignUp: "Sign Up",
        Youremailorusernameisincorrect: "Your email or username is incorrect",
        isGoogleMsg:
          "This account can only be logged into with Google, or by resetting the password with “Reset password",
        isFaceBookMsg:
          "This account can only be logged into with Facebook, or by resetting the password with “Reset password.",
        Continue: "Continue",
      },
      logIn: {
        tabTitle: "Log in to Capperauditor",
        Welcome: "Welcome",
        Changeemail: "Change",
        ChangeUserName: "Change",
        YourPassword: "Your Password",
        Enteryourpassword: "Enter your password...",
        ForgotPassword: "Forgot Password?",
        ErrorMsg: `You entered an incorrect password, try again or use "Reset password”`,
        LogIn: "Log In",
      },
      resetPassWord: {
        tabTitle: "Reset password",
        resendEmailTitle: "Reset password",
        errorMsg: "Please enter a valid email",
        decriptMsg:
          "Enter your email below, you will receive an email with instructions on how to reset your password in a few minutes.",
        Email: "Email",
        Enteryouremailaddress: "Enter your email",
        Resetpassword: "Reset password",
        // reset new password
        setNewPassBtn: "Set a new password",
        newPasswordTabTitle: "Set a new password",
        SetnewpasswordBtn: "Set a new password",
        newPasswordTitle: "Set a new password",
        Newpassword: "New password",
        Enteryourpassword: "Enter your password...",
      },
      signUp: {
        tabTitle: "Sign up for free on Capperauditor",
        tabDecription:
          "Create an account on Capperauditor and get 1000 bonus CAP coins to your account.",
        title: "Create an account",
        subTitle: "Share, track and analyze your sports predictions",
        isGoogle: "Continue with Google",
        isFaceBook: "Continue with Facebook",
        or: "or",
        Youremail: "Email",
        Password: "Password",
        Continue: "Continue",
        Alreadyaccount: "Already have an account?",
        Login: "Log in",
        Enteryourpassword: "Enter your password ...",
        PrivacyPolicyLine: "By creating an account, you agreeing to our",
        PrivacyPolicy: "Privacy Policy",
        alreadyExistMsg: "Email is already exist",
        InvalidMsg: "Email is Invalid",
        fieldRequired: "This field is required",
      },
      checkMail: {
        tabTitle: "Check your email",
        subTitle: "Check your email",
        firstMsg: "We’ve sent a special link to",
        secondMsg: "Click the link in the email to confirm your email address.",
        Wrongemail: "Wrong email?",
        ReEnterAddress: "Change",
      },
      returnToLogin: {
        CheckemailTitle: "Check your email",
        returnToLoginSubTitle1: "An email has been sent to",
        returnToLoginSubTitle2: "with instructions to reset your password.",
        decriptionTitle:
          "If the email doesn’t show up soon, check your spam folder or make sure you enter the email you used for registration.",
        returnToLoginBtn: "Return to login",
      },
      settingUpAccount: {
        subTitle: "Track, analyze, improve you bettings",
      },
      register: {
        tabTitle: "Finish setting up your account",
        title: "Finish setting up your account",
        subTitle: "Share, track and analyze your sports predictions",
        name: "Name",
        namePlaceHolder: "Enter your name",
        LastName: "Last Name",
        lastNameHolder: "Enter your last name",
        Username: "Username",
        UsernamePLaceHolder: "e.g capperauditor",
        registerBtn: "Finish setting up",
        alreadyExistMsg: "This username is already taken",
        Namemin3Max15ErrorMsg: "First name should contain from 3 to 18 letters",
        lastmin3Max15ErrorMsg:
          "The last name must contain from 3 to 18 letters",
        NameonlyLettersMsg: "Name cannot contain characters",
        userNameonlyLettersMsg: "Last Name cannot contain characters",
        min5Max15ErrorMsg: "Username can contain only English letters",
        lengthCountMsg: "Username should contain from 5 to 20 letters",
        noSymbollOnlyCharMsg: "Username cannot contain characters",
        // noSymbollOnlyCharMsg: "Username can contain only English letters",
        fieldRequired: "This field is required",
      },
      error: {
        avatarContent: "Avatar content type is invalid.",
        avatarSize: "Your image is larger than 4 MB.",
        avatarDefault: "JPG or PNG. Max size of 4MB.",
        avatarDeleteBtn: "Delete picture",
        avatarDeleteMsg: "Are you sure you want to delete your picture?",
        avatarDeleteConfirm: "Yes, Delete",
        avatarDeleteDiscard: "No",
        confirmMsg: "Yes",
      },
      profile: {
        oddsTooltipMsg:
          "This prediction contains an event that has been returned.The first number is the initial odd, the second is the final odd that will be included in the statistics.",
        Averageodd: "Average odd",
        Shapedata: "Form",
        Latest5predictions: "Latest 5 predictions",
        Latest: "Latest",
        alertCircleTooltipMsg: "User form based on the last 5 predictions",
        searchBarPlaceHolder: "Search for event or predictor",
        YourSportLine: "Your Sport Line",
        followers: "Followers",
        follow: "Follow",
        unfollow: "Unfollow",
        following: "Following",
        followingBtn: "Following",
        predictions: "Predictions",
        analytics: "Statistics",

        noFollowersText:
          "Here will be displayed sports predictors as soon as someone starts following you.",
        noFollowersTitle: "Users following you",
        noFollowingText:
          "Here will be displayed sports predictors as soon as you start following them",
        noFollowingTitle: "Sports predictors you're following",

        noPredictionYetProfile: "You have not published any prediction yet",
        noPredictions: "The user hasn't published any predictions yet",
        addPredictions: "Add prediction",
        editProfile: "Edit Profile",
        addYourBio: "+ Add your bio",
        bio: "Bio",
        saveCancel: "Cancel",
        saveConfirm: "Save profile",
        shareProfile: "Share Profile",
        ShareSocialMedia: "Share on social media",
        linkToShare: "Link to share",
        copyLink: "Copy link",
        copied: "Copied",
        selectSport: "Select sport",
        search: "Search",
        allLeagues: "All leagues",
        popularLeagues: "Popular leagues",
        LEAGUESBYCOUNTRY: "LEAGUES BY COUNTRY",
        selectSport: "Select sport",
        selectLeague: "Select league",
        showOddsLine: "Show odds line",
        countries: "Countries",
        titleAddPrediction: "Add Prediction",
        AddSport: "Add sport",
        ManageSports: "Manage sports",
        slipEmptyMsg: "Your prediction slip is empty",
        addBitSlip:
          "Browse the line and select predictions by clicking on the odds.",
        allTab: "All",
        allLive: "Live",
        allSheduled: "Sheduled",
        ExitManaging: "Exit managing",
        DeleteSport: "Delete sport",
        deleteSportMsg: "Are you sure you want to delete",
        EditLeagues: "Edit leagues",
        Parlay: "Multiple",
        events: "events",
        notificationParlay: "Parlay",
        ClearAll: "Clear all",
        TotalOdds: "Total odds",
        PredictionDescription: "Description",
        PlacePrediction: "Place prediction",
        deleteSportNotificationMsg:
          "The <span>{{selectedSportName}}</span> was deleted",
        DoubleChance: "Double chance",
        FullTimeResult: "Full-time result",
        Total: "Total",
        Under: "Under",
        Over: "Over",
        Handicap: "Handicap",
        RealMadridTotal: "Real Madrid Total",
        Teamtoscorethegoal: "Team to score the goal №",
        Chelse: "Chelse",
        None: "None",
        RealMadrid: "Real Madrid",
        Bothteamstoscore: "Both teams to score",
        Correctscore: "Correct score",
        Single: "Single",
        Notenoughdata7Days:
          "There are not enough predictions for the last 7 days",
        Notenoughdata1Month:
          "There are not enough predictions for the last 1 month",
        Notenoughdata3Month:
          "There are not enough predictions for the last 3 months",
        Notenoughdata6Month:
          "There are not enough predictions for the last 6 months",
        Notenoughdata1Year:
          "There are not enough predictions for the last 1 year",
        NotenoughdataAllYear:
          "There are not enough predictions for this period",
        tryAgainLine: "Please try again later",
        Notdatadisplay: "Not enough data to display",
        textAreaPlaceHolder: "Brief description for your profile.",
        Top3predictions: "Top 3 predictions",
        Event: "Event",
        Predictiontype: "Prediction",
        Odds: "Odds",
        TopPrediction:
          "This is a multiple prediction.Click to see full prediction",
        PredictionDetails: "Prediction details",
        Postedon: "Posted on",
        TopPredictionEmptyData: "No predictions yet",
        TopPredictionEmptySubTitle: "Please try again later",
      },
      chartSummary: {
        titleBarWon: "Won",
        titleBarLost: "Lost",
        titleBarReturns: "Returned",
        Balance: "Current balance",
        CollectedCoins: "Collected coins",
        Odds: "Odds",
        tooltipLosses: "Losses",
        tooltipReturns: "Returns",
      },
      predictionFilter: {
        filterWon: "Won",
        filterReturned: "Returned",
        MoreBtn: "More",
      },
      navbar: {
        myProfile: "My profile",
        feed: "Predictions",
        Giveaways: "Giveaways",
        profile: "Profile",
        settings: "Settings",
        logout: "Log out",
        AdminPanel: "Admin panel",
        notification: {
          Notifications: "Notifications",
          pendingNotifications: "pending notifications",
          Clearall: "Clear all",
          ViewAll: "View All",
          Nonewnotifications: "No new notifications",
          NotificationsTitle: "Notifications",
          All: "All",
          Predictions: "Predictions",
          Usersactivity: "Users activity",
          Nodatayet: "There are no notifications yet.",
          Previous: "Previous",
          Next: "Next",
        },
        confirmModel: {
          firstTitle: "Create an account and get a bonus",
          subTitle1: "Register now and get",
          subTitle2: "CAP coins",
          subTitle3: "to your account.",
          confirmModelBtn: "Sign up and get a bonus",
        },
        Marketplace: "Store",
        Leaderboard: "Leaderboard",
      },
      setting: {
        metaTitle: "Settings",
        settings: "Settings",
        general: "General",
        profile: "Profile",
        security: "Security",
        language: "Language",
        currency: "Currency",
        oddsFormat: "Odds format",
        saveChanges: "Save changes",
        changesSaved: "The changes was saved",
        name: "Name",
        username: "Username",
        email: "Email",
        currentPassword: "Current password",
        newPassword: "New password",
        confirmPassword: "Confirm Password",
        successedMessage: "You have successfully updated your password",
        currentPasswordWrong: "You entered wrong password",
        passwordMismatch: "Password not match",
        verifyEmailTitle: "Verify your new email address",
        verifyEmailContaint:
          "We’ve sent an email to <span class=verify-email>{{email}}</span>. Your change will not be complete until you verify your new email address.",
        cancelChange: "Cancel Change",
        resendEmail: "Resend Email",
        emailError: "Email already exist",
        nameUpdatedMessage: "Name updated successfully",
      },
      feed: {
        metaTitle: "Free sports predictions",
        metaDescription:
          "The largest list of predictions for football, Champions League, basketball, tennis, hockey, volleyball and other sports.",
        Parlay: "Multiple",
        predictionTab:
          "Sports predictions | Football predictions | Capperauditor.",
        predictionMetaTitle:
          "Sports predictions | Football predictions | Capperauditor",
        predictionMetaDescription:
          "Get free predictions for football, basketball, hockey and other sports from experts.",
        shareModelPrediction: "Predictions",
        lockSearchBarMsg: "Search is only available to Capperauditor members",
        lockFilterMsg: "Filtering is only available to Capperauditor members",
        Recomended: "Recomended",
        Filters: "Filters",
        predictionsFound: "predictions found",
        SortBy: "Sort by",
        Newest: "Newest",
        Popular: "Popular",
        Odds: "Odds",
        Odd: "Odd",
        PopularEvents: "Popular events",
        Sport: "Sport",
        Tournament: "Tournament",
        PredictionType: "Prediction type",
        Status: "Status",
        OddsRange: "Odds range",
        ClearFilters: "Clear-filters",
        Apply: "Apply",
        Makeprediction: "Make a prediction",
        NoResultsFound: "No results found",
        alertMsg: "Please select a sport first",
        ShowMorePredictions: "Show more predictions",
        showMessage: "You’ve reached the end of the list",
        Pending: "Pending",
        win: "Wins",
        Won: "Won",
        Lost: "Losses",
        Returned: "Returned",
        Padding: "Padding",
        Prediction: "Prediction",
        socialCommunity:
          "Share this with your social community or download as png file",
        PredictionDate: "Prediction Date",
        joinCapperauditore: "Scan the QR code to open the prediction page",
        Multiple: "Multiple",
        Summary: "Summary",
        Alltournaments: "All tournaments",
        Allupcoming: "All upcoming",
        Addtournament: "Add tournament",
        Today: "Today",
        Tomorrow: "Tomorrow",
        ThereAreNoMatches: "There are no events",
        tournamentOrDay:
          "No events are scheduled. Check back later or choose another tournament or day",
        More: "More",
        Detailedstatistics: "Detailed statistics",
        Generalstatistics: "General statistics",
        D7: "7D",
        m1: "1M",
        m3: "3M",
        m6: "6M",
        y1: "1Y",
        All: "ALL",
        statusWon: "Won",
        statusLost: "Lost",
        modelWon: "WON",
        modelLost: "LOST",
        modelReturn: "RETURNED",
        modelPending: "PENDING",
        statusReturn: "Returned",
        dropDownReturned: "Returned",
        statusPending: "Pending",
        ModelstatusWon: "Won",
        ModelstatusLost: "Lost",
        ModelstatusReturn: "Return",
        ModelstatusPending: "Pending",
        And: "And",
        morePredictions: "more-predictions",
        betSlipTextAreaPlaceHolder: "Describe your prediction here...",
        OpenPrediction: "Open prediction",
        StakedCoins: "Staked coins",
        PossibleWin: "Possible gain",
        Type: "Type",
        Status: "Status",
        PreGame: "Pre-game",
        TotalOdds: "Total Odds",
        tooltipMsg:
          "This prediction contains an event that has been returned.The first number is the initial odd, the second is the final odd that will be included in the statistics.",
        DeleteUser: "Delete",
        edit: "Edit",
        EditPredictionModelTitle: "Edit prediction",
        DeletePredictionTitle: "Delete prediction",
        DeletePredictionMessage:
          "Are you sure you want to delete the prediction?",
        PredictionStatus: "Status",
        Score: "Score",
        succesTooltipMsgTitle: "Your prediction was posted successfully",
        succesTooltipMsgSubTitle:
          "You can go to your prediction by clicking the button below",
        myPredictionBtn: "Go to my prediction",
        ErrorTooltipMsgTitle: "Something went wrong",
        ErrorTooltipMsgsubTitle: "Bets have been suspended, please try again",
        errorCode3: "Result does not exist.",
        errorCode99: "Unknown error",
        errorCode1: "Unable to get totals or data is corrupted.",
        ErrorCode0: "good. Coupon accepted.",
        ErrorMainTitle: "Something went wrong",
      },
      homePage: {
        metaTitle: "Social network for sports analysts and sport tipsters",
        metaDescription:
          "Create free sports predictions, track your prediction statistics, and accumulate coins that you can later exchange for real prizes.",
        Predictions: "Predictions",
        LogIn: "Log in",
        account: "Create account",
        resourcesTitle:
          "All the resources you need to improve and control your sport predictions",
        HandicappersSports:
          "Social network for sports analysts and sport tipsters",
        HandicappersSportsDecription:
          "Publish free sports predictions, track your prediction statistics, and accumulate coins that you can later exchange for real prizes.",
        getStartedButton: "Create a free account with email",
        DifferentSportsTitle: "14 sports",
        DifferentSportsContains: "Make predictions on the sport you like.",
        LargeCommunity: "A community of like-minded people",
        LargeCommunityContains:
          "Find your friends, like-minded people, and share your predictions with each other.",
        DetailedAnalytics: "Detailed statistics",
        DetailedAnalyticsContains:
          "Track how successful your predictions are in real time.",
        Realtimesportsdataodds: "Real odds",
        RealtimesportsdataoddsContains: "Real odds in real time.",
        BiggestLineofevents: "Large sports line",
        BiggestLineofeventsContains:
          "One of the largest sports lines where you will definitely find the tournament and team you need.",
        largestPredictions: "The largest list of predictions",
        largestPredictionsContains:
          "Follow the predictions of your favorite sport events.",
        BrowsePredictionsTitle: "Predictions",
        BrowsePredictions1Quotes:
          "Publish and share your predictions with others.",
        BrowsePredictions2Quotes:
          "Follow the predictions of your friends and experts.",
        BrowsePredictions3Quotes: "Share your predictions on social media",
        ButtonPrediciton: "View predictions",
        Analytics: {
          Statistics: "Statistics",
          GeneralStatistics: "General statistics:",
          Wins: "Wins",
          Losses: "Losses",
          Returns: "Returns",
          DetailedStatisticsfor: "Detailed statistics for:",
          Week: "Week",
          Month: "Month",
          Months3: "3 Months",
          Months6: "6 Months",
          Year: "Year",
          AllTheTime: "All the time",
          ButtonCheckOut: "Get started for free",
        },
        SportsEvents: {
          SpecialList: "Customized sports line",
          Maang:
            "Customize your sports line to suit your needs. Choose the sport and tournament you are interested in.",
          Frameworks:
            "Filter out uninteresting tournaments and keep your favorites.",
        },
        FavoriteSport: {
          Sports: "View the predictions of your favorite sport",
          ButtonAllSports: "View all sports predictions",
          ShowMoreSports: "Show more sports",
          ShowLessSports: "Show less sports",
        },
        CreateAccount: {
          SharePrediciton:
            "Create an account and publish your first prediction",
          LoremIpsum:
            "The sports prediction platform invites you to share your own predictions, monitor statistics, analyze results and view the predictions of your friends and experts.",
          ButtonStarted: "Get Started for FREE",
        },
        Community: {
          CommunitySaying: "People say about us",
        },
        FAQ: {
          FreqeuntlyQuestions: "FAQ",
          DesignState: "Is Capperauditor a bookmaker?",
          ProductManagement:
            "No, it's a social network where you can post and share your own predictions and view other users' predictions.",
          Exercises: "Can I place bets on the website?",
          completelyFree:
            "No, you can only make predictions that are completely free.",
          platform: "Is the platform free?",
          platformFeatures: "Yes, all the features on the site are free.",
          user1name: "Vlad Miroshnichenko",
          user1nameContains:
            "I'm very happy to have discovered this platform, where I can see other users' predictions for my favorite matches. It's really interesting and useful. 😍",
          user4name: "Nazar Melnychuk",
          user4nameContains:
            "Another advantage of the platform is its user-friendly and simple interface. I can easily find the information I need and interact with other users.",
          user7name: "Vitaliy Abramchuk",
          user7nameContains:
            "I appreciate the ability to see other users' predictions and statistics. It allows me to see different perspectives and make more informed predictions.🔥",
          user2name: "Andriy Sholokh",
          user2nameContains:
            "I've been looking for a platform where I could see other people's predictions and statistics for a long time. With capperauditor it's convenient and easy.🔥🔥",
          user5name: "Kateryna Andrienkо",
          user5nameContains:
            "I'm sure that capperauditor is the best platform for those who want to keep up with sports events and make correct predictions.",
          user8name: "Vladislav Shpak",
          user8nameContains:
            "I believe that capperauditor is a great platform for those who want to increase the number of successful predictions.",
          user3name: "Oleksandr Tkachenkо",
          user3nameContains:
            "I love sports, so capperauditor is the perfect place for me to get all the information I need about matches and make the right predictions.",
          user6name: "Valery Bozhenko",
          user6nameContains:
            "As a person who loves analyzing data, I am very impressed with the data capperauditor provides. It really helps me to make the right predictions.",
          user9name: "Yevhenia Symonenko",
          user9nameContains:
            "I'm very glad that capperauditor allows you to create your own predictions and share them with other users. It really helps to develop and learn.",
        },
        homePageFooter: {
          AllInOne: "Post sports predictions for free and win real prizes",
          SecondDecription:
            "We are not affiliated with the gambling industry in any way. Our website provides a range of free tools that do not require any financial transactions.",
          Navigation: "Navigation",
          GetInTouch: "Get in touch",
          FollowUs: "Follow Us",
          Predictions: "Predictions",
          PrivacyPolicy: "Privacy Policy",
          Cookies: "Cookies",
          AllRightsReserved: "All Rights Reserved.",
        },
        homePrediction: {
          SignIn: "Sign in to Capperauditor",
        },
      },
      admin: {
        Dashboard: "Dashboard",
        Users: "Users",
        Hello: "Hello",
        checkStatsToday: "Let’s check capperauditor stats today",
        checkUserToday: "Let’s check capperauditor users",
        adminSearchPlaceHolder: "Search user by name, username or email",
        usersfound: "users found",
        NewUsersToday: "New users today",
        NewpredictionsToday: "New predictions today",
        totalUsers: "total users",
        totalPredictions: "total predictions",
        userTable: {
          user: "User",
          Country: "Country",
          Email: "Email",
          Status: "Status",
          SRate: "S. Rate",
          Pred: "Pred.",
        },
        PendingBtn: "Pending",
        ActivatedBtn: "Activated",
        Changeuserpermission: "Change user permissions",
        DeleteUser: "Delete user",
        SeeAll: "See all",
        SeeAllUsers: "See all users",
        sureityMsg: "Are you sure you want to delete the user",
        ChangeUserPermission: "Change user permission",
        Admin: "Admin",
        SingleUser: "Single User",
        Loadmoreusers: "Load more users",
        UserSettings: "User settings",
        GeneralsettingsTitle: "General settings",
        GeneralsettingsSubTitle: "Let’s check capperauditor stats today",
        Signupreward: "Sign up reward",
      },
      notificationMOdel: {
        likeDecriptMsg: "liked your prediction",
        dislikeDecriptMsg: "disliked your prediction",
        followingMsg: "started following you",
      },
      giveAways: {
        metaTitle: "Tournaments. Big rewards for accurate predictions",
        metaDecription:
          "Guess the exact scores in different matches and win CAP coins. Participation is free of charge.",
        giveAwaysContent: "Make free predictions and win real money",
        CreateGiveawaybtn: "Create giveaway",
        Participants: "Participants",
        Reward: "Reward",
        emptyMsg: "No participants yet",
        JoinGiveawayBtn: "Join giveaway",
        Joined: "Joined",
        Calculation: "Calculation",
        Winner: "Winner",
        GiveawayIsFinished: "Giveaway is finished",
        SearchLeagueSearch: "Search league or country",
        Bycountry: "By country",
        SelectMatch: "Select match",
        Searchteamname: "Search team name",
        Sеtanaward: "Sеt an award",
        ie: "i.e ₴1000",
        Numberofwinners: "Number of winners",
        CreateGiveawayBtn: "Create giveaway",
        RulesTitle: "Rules",
        Rules: {
          firstRule:
            "If the number of users who have made the correct prediction exceeds the maximum allowed number of winners, our algorithm will randomly select the winner(s).",
          secondRule:
            "If you win, after the end of the giveaway, you will receive an email with further instructions.",
          thirdRule:
            "If we do not receive a response from you within 3 days after the end of the giveaway, your prize will be forfeited",
        },
        TakePartTitle: "Take part in the giveaway",
        TakePartSubTitle:
          "To participate in the giveaway, please follow these steps",
        socialMedia: "Follow us on social media",
        socialMediaSubTitle:
          "Follow us on social networks and confirm your subscription",
        ConfirmSubscriptionBtn: "Confirm subscription",
        makeprediction: "Make your prediction",
        makePredictionSubTitle:
          "Choose the correct score that you think is the most likely",
        ConfirmSubscription: {
          ConfirmSubscriptionTitle: "Confirm subscription",
          ConfirmSubscriptionSubTitle:
            "Please provide your username in the following social networks so that we can check if you are following us.",
          EditUsernamesTitle: "Edit usernames",
          SaveUsernamesBtn: "Save usernames",
        },
        MakepredictionBtn: "Make prediction",
        EditUsernamesBtn: "Edit usernames",
        predictions: {
          predictionsTitle: "Predictions",
          predictionsSubTitle: "Predictions made by the participants",
          JoinGiveawayBtn: "Join Giveaway",
        },
        Winner: {
          WinnerTitle: "Winner",
          WinnerSubTitle: "Congratulations to the winner(s)",
        },
        PerfectPredictors: {
          PerfectPredictorsTitle: "Perfect predictors",
          PerfectPredictorsSubTitle:
            "Other participants who guessed the exact score",
          onlyTwoPerfectPredictorsLine:
            "There are no other participants who guessed the correct score",
          noPerfectPredictors:
            "Unfortunately, no one guessed the exact score, so stay tuned for new giveaway",
        },
        AllPredictions: {
          AllPredictionsTitle: "All predictions",
          AllPredictionsSubTitle:
            "All the predictions made by the participants",
        },
        ParticipantsTable: {
          UserTitle: "User",
          InstagramTitle: "Instagram",
          TikTokTitle: "TikTok",
          ConfirmwinnerTitle: "Confirm winner",
        },
      },
      sportLine: {
        sportlineTitle: "Sport Line",
        MoreLabel: "More",
        PredictionSlip: "Prediction slip",
        newBetslipTitle: "Prediction coupon",
        newBetslipsubTitle: "Your coupon is empty",
        seeAll: "See all",
        AllCompetitions: "All leagues",
        BetTotal: "Bet total",
        Minimum10: "Minimum 10",
        Sports: "Sports",
        Competitions: "Select a league",
        Available: "Available",
        max: "Max",
        bottomTextTitle:
          "Sports Line: Odds for Football, Boxing, and Other Sports",
        bottomTextDescription:
          "Current odds for football, hockey, boxing, and other sports! Live odds available for over 14 sports.",
        HowCoinsDescription: "How to earn free CAP coins ?",
        LearnMore: "Learn more",
        AddDescription: "Add description",
        optional: "optional",
        minimumStake: "Minimum stake is 10 CAP",
        notEnoughFund: "Not enough funds in your account. Your balance",
        metaTitle: "Sports Line · 14 different sports",
        metaDescription:
          "Extensive Array of Sporting Events · The highest and most accurate odds",
        metaDescription1: "All sports events. Live bets:",
        metaDescription2: ":star: High odds ...",
      },
      wallet: {
        WalletTitle: "Wallet",
        metaTitle: "My Balance",
        MyCAPcoinsTitle: "My CAP coins",
        MyCAPcoinsSubTitle:
          "Coins Capperauditor (CAP) - is the official currency of our site, which is provided for free. Collect them, earn and exchange for real prizes in our store.",
        Balance: "Balance",
        coinsLastWeek: "coins last week",
        CollectedCoins: "Collected coins",
        EarnedCoins: "Earned coins",
        collectCoinEveryDayTitle: "Collect your CAP coins every day",
        collectCoinEveryDaySubTitle:
          "Log in every day in a row and your rewards will grow.",
        CollectCoinsBtn: "Collect coins",
        Collect: "Collect",
        amount: "Amount",
        CollectedBtn: "Collected",
        toCollectBtn: "to collect",
        mobileCollectCoin: "Collected",
        mobileEarnCoin: "Earned",
        mobileSpanCoin: "Spent",
        EarnCollectedCoinsTab: "Collected coins",
        EarnedCoinsTab: "Earned coins",
        EarningHistoryTitle: "History",
        EarningHistorySubTitle: "History of collected, earned and spent coins",
        Activity: "Activity",
        CoinsAmount: "Coins amount",
        Date: "Date",
        Event: "Event",
        StakedCoins: "Staked coins",
        Odd: "Odd",
        EarnedCoins: "Earned coins",
        modelTitle: "Yay, you've collected",
        coins: "coins!",
        comeBackMessage: "Come back tomorrow and get more coins.",
        OkThanks: "Ok, thanks",
        noRewardTitle: "Achievement history is empty",
        noRewardSubTitle:
          "As soon as you get an achievement, they will all appear here.",
        noPostDataTitle: "You have not post any predictions yet",
        noPostDataSubTitle:
          "After you make your first prediction, your earnings history will appear here",
        titleAddPrediction: "Add Prediction",
        showMore: "Show more",
        Day: "Day",
        eachNextDay: "Each next day",
        SignUpreward: "Sign Up reward",
        SpentCoins: "Spent coins",
        Purchase: "Purchase",
        emptymainTitle: "You don't have any purchases yet",
        emptyMainSubtitle:
          "After you make your first purchase, your spending history will appear here",
        emptyStateBtn: "Go to the Store",
      },
      Marketplace: {
        metaTitle: "Store. Exchange CAP coins for real prizes",
        metaDescription:
          "Exchange your CAP coins for real prizes in the Capperauditor Store. Turn your analytical skills into valuable gifts.",
        MarketplaceTitle: "Store",
        MarketplaceSubTitle:
          "Welcome to our Store, where your CAP coins gain tangible value! Explore an expansive array of real products, all available for purchase using the CAP coins you've earned on our website.",
        costTitle: "Cost",
        BuyNowBtn: "Buy now",
        editProductTooltip: "Edit product",
        deleteProductTooltip: "Delete product",
        soldOutBtn: "SOLD OUT",
        addProductBtn: "Add new product",
        purchaseSuccessfullMsg: "The purchase was completed successfully",
        purchaseMsg1: "You have successfully purchased",
        purchaseMsg2:
          ". All details on how to receive your order have been sent to your email",
        purchaseMsg3:
          "If you have not received a notification by mail, write to our support",
        DeleteProductTitle: "Delete product",
        DeleteProductMessage: "Are you sure you want to delete the product",
        addProductTitle: "Add new product",
        addModelTitle: "Title",
        PriceTitle: "Price",
        Status: "Status",
        DeletePhoto: "Delete photo",
        ProductPlaceHolder: "Product title",
        PricePlaceHoldrer: "e.g 20 000",
        statusPlaceHolderActive: "Active",
        statusPlaceHolderSoldout: "Sold out",
        UploadPhotoBtn: "Upload photo",
        AddProduct: "Add product",
        DeletephotobTN: "Delete photo",
        onluNumricValueMsg: "A number is expected.",
        disableBtnTooltip:
          "You do not have enough coins to make a purchase. To learn how to earn more coins click ",
        toolTipHear: "here",
        EditPoroduct: "Edit product",
      },
      Leaderboard: {
        metaTitle: "Leaderboard",
        metaDescription: "Leaderboard",
        Title: "Leaderboard",
        SubTitle: "The top predictors on CapperAuditor.",
        signupTitle: "Sign Up and Get Full Access to the Rankings.",
        signupText: "Join now and receive 1000 bonus CAP coins to your account",
        signupBtn: "Join Now",
        Grid: {
          title: "Leading Predictors",
          dropdown: {
            allTime: "All time",
            week: "Week",
            month: "Month",
          },
          tableHeaders: {
            predictor: "Predictors",
            predictions: "Predictions",
            avg: "Avg. coef.",
            spc: "SPC",
            sport: "Sport",
            coins: "CAP coins",
          },
          tableHeaderHints: {
            avg: "Average coefficient of successful predictions",
            spc: "The Success Predicion Coefficient (SPC) is the percentage of successful predictions compared to failures",
            sport: "The sport with highest number of predictions",
          },
        },
      },
    },
  },
  ua: {
    translation: {
      ad: "Реклама",
      line: "Лінія",
      readMore: "Читати далі",
      half_time: "Перерва",
      mintute: "хв.",
      period_1: "1-Й ТАЙМ",
      period_2: "2-Й ТАЙМ",
      or: "або",
      signUpTxt: "Зареєструватись",
      signInTxt: "Увійти",
      resultsPage: {
        title: "Результати",
      },
      allSports: "Усі види спорту",
      predictions: {
        bottomTextTitle:
          "Безкоштовні прогнози на спорт на сьогодні, завтра та експреси.",
        bottomTextDescription:
          "На Capperauditor вас чекає понад {{count}} прогнозів на 14+ видів спорту, актуальні прогнози, експреси та багато іншого. Спілкуйтеся з однодумцями, слідкуйте за експертами та насолоджуйтеся активною спортивною спільнотою.",
      },
      gameDetail: {
        overView: "Огляд",
        avgCoef: "Середній коефіцієнт",
        avgPrice: "Середня сума ставки",
        predictions: "Прогнози",
        analytics: "Аналітика",
        totalPredictionsHint: "Загальна кількість прогнозів",
        avgCoefHint: "Середній коефіцієнт всіх зроблених прогнозів",
        avgPriceHint: "Середня сума ставки всіх зроблених прогнозів",
        chat: "Ком’юніті",
        predictionOverview: {
          title: "Огляд прогнозів",
          before: "Перед матчем",
          live: "Live прогнозів",
          single: "Ординари",
          express: "Експреси",
          beforeHint: "Кількість прогнозів, зроблених перед початком матчу",
          liveHint: "Кількість прогнозів, зроблених під час матчу в режимі Live",
          singleHint: "Загальна кількість одиночних прогнозів (ординарів)",
          expressHint: "Загальна кількість комбінованих прогнозів (експресів)",
          total: "Всього прогнозів"
        }
      },
      rewards: {
        metaTitle: "Досягнення",
        metaDescription: "Будьте активними та здобувайте нові досягнення.",
        modalTitle: "Велике оновлення: Запускаємо систему досягнень",
        modalSubTitle:
          "Щиро раді повідомити, що ми запустили систему досягнень! Тепер ваші зусилля на нашому сайті будуть ще більше винагороджені. Досягнення не лише роблять гру цікавішою, але й дарують вам додаткові CAP-монети за вашу активність. Нехай це буде точний прогноз, регулярне збирання монет або збільшення кількості підписників - кожен ваш крок буде відзначений винагородою.",
        close: "Закрити",
        open: "Перейти до досягнень",
        rewardCoinsTab: "Нагороди",
        timeTo: "Залишилось до завершення турніру",
        level: "Рівень",
        progress: "ПРОГРЕС",
        award: "Нагорода",
        end: "Завершено",
        openRewards: "Відкрити досягнення",
        noRewardTitle: "Наразі у вас ще немає досягнень",
        noRewardSubTitle:
          "Будьте активними, і ваші перші досягнення з’являться тут незабаром.",
        noRewardUserTitle: " ще не має досягнень",
        noRewardUserSubTitle:
          "Як тільки користувач здобуде свої перші досягнення, вони відразу з'являться тут.",
        showAll: "Показати всі",
        showAllRewards: "Показати всі досягнення",
        rewardCompleted: "Досягнення здобуто",
        rewardWait: "Незабаром",
        rewardWaitTooltip: "Ексклюзивне досягнення стане доступним вже скоро",
        rewardOver: "Завершено",
        rewardOverTooltip:
          "Турнір завершився, але не засмучуйтесь, вже невдовзі з'являться нові ексклюзивні досягнення.",
        levelsCompleted: "Ви пройшли всі рівні цього досягнення.",
        levelsCompletedExclusive:
          "Ексклюзивне досягнення вже поповнило вашу колекцію досягнень.",
        claim: "Отримати винагороду",
        analyst: "Спортивний аналітик",
        collector: "Колекціонер",
        influencer: "Інфлюенсер",
        partner: "Надійний партнер",
        exclusive: "Ексклюзивні досягення",
        continueLbl: "Продовжити",
        rewardTitle: "Ви здобули {{level}}-й рівень досягнення ",
        rewardTitleExclusive: "Ви здобули ексклюзивне досягнення ",
        rewardDescription: "На ваш рахунок нараховано ",
        capCoins: "CAP монет.",
        reward: "Досягнення",
        titleDesc: "Будьте активними та здобувайте нові досягнення.",
        motivation1:
          "Ми стежимо за вашими успіхами і впевнені, що ваші зусилля принесуть вражаючі результати.",
        motivation2:
          "Ви крутіші ніж 50% користувачів. Продовжуйте свій шлях в тому ж дусі 🔥",
        motivation3:
          "Ваші темпи вражають. Ви вже обігнали 65% користувачів. 👏",
        motivation4:
          "Ви у топ-10% найкращих! Лишилося ще трошки, щоб опинитися на вершині. 💪",
        motivation5:
          "Ви справжній лідер! Ви увійшли в 1% найкращих прогнозистів🥇",
      },
      noGamePage: {
        title: "Матч закінчився або сторінка не існує",
        text: "Упс! Здається, гру, яку ви шукаєте, або вже завершилась, або не існує. Будь ласка, перейдіть до спортивної лінії, щоб переглянути інші події.",
        btn: "Перейти до спортивної лінії",
      },
      emptyPageText: {
        noFollowingPredictionsTitle: "Прогнозів ще немає",
        noFollowingPredictionsText:
          "Тут з'являться прогнози від користувачів, за якими ви слідкуєте, коли вони будуть опубліковані.",
        noFilteredPredictionsTitle: "Немає відповідних прогнозів",
        noFilteredPredictionsText:
          "На жаль, за вашими вибраними фільтрами не знайдено жодного прогнозу. Будь ласка, змініть параметри фільтрування або спробуйте пізніше.",
      },
      homePageSigninPage: {
        SignIn: "Увійдіть в Capperauditor",
        isGoogleBtn: "Продовжити з Google",
        isFabookBtn: "Продовжити з Facebook",
        or: "або",
        emailorusername: "Ваш email або ім'я користувача",
        EnteremailPlaceHolder: "Введіть свій email або ім'я користувача",
        Continue: "Далі",
        subLine1: "Не маєте профілю?",
        subLine2: "Зареєструйтеся",
      },
      mainHomePage: {
        metaTitle:
          "CapperAuditor - Прогнози на спорт, Статистика, Спортивне ком'юніті.",
        metaDescription:
          "Cоціальна мережа для любителів спорту. ✪ Робіть прогнози, аналізуйте статистику, спілкуйтеся з однодумцями та слідкуйте за найкращими експертами.",
        metaSocialTitle:
          "CapperAuditor - Прогнози на спорт, Статистика, Спілкування",
        metaSocialDesc:
          "Безкоштовні прогнози на футбол, баскетбол, теніс та інші види спорту. ✪ Спілкуйтеся з друзями, аналізуйте статистику та отримуйте актуальні поради.",
        Free: "Безкоштовно",
        publish: "публікуйте ",
        mainTitle: "прогнози на спорт та вигравайте реальні призи",
        mainSubTitle:
          "Соціальна мережа, де кожен може робити безкоштовні прогнози на спорт, відслідковувати статистику, заробляти монети та обмінювати їх на реальні призи",
        defaultCoinTitle: " монет у подарунок новим користувачам",
        FreeRegistration: "Безкоштовна реєстрація",
        watchVideoBtn: "Дивитися відео ( 1хв )",
        counter1: "Прогнозистів приєдналося",
        counter2: "Прогнозів зроблено",
        counter3: "Монет зароблено",
        counter4: "Монет зібрано",
        counter5: "Середній коефіцієнт прогнозів",
        sportsKnowledgeIntroTitle:
          "Три прості кроки, щоб почати монетизовувати свої знання в спорті",
        sportsKnowledgeIntroSubTitle:
          "Робіть прогнози на спорт, отримуйте монети, обмінюйте на реальні призи.",
        Step: "Крок",
        step1Title: "Публікуйте прогнози на спорт",
        step1SubTitle:
          "Публікуйте свої прогнози безкоштовно перед початком подій, чи в режимі онлайн. У вашому доступі спортивна лінійка, яка включає 14 видів спорту та тисячі доступних подій, серед яких ви точно знайдете ті, які зацікавлять вас",
        step1Btn: "Опублікувати прогноз",
        step2Title: "Отримуйте віртуальні монети",
        step2EarnTab: "Заробляйте монети",
        step2CollectTab: "Збирайте монети",
        step3Title: "Обмінюйте свої монети на реальні призи",
        step3SubTitle:
          "Зароблені та зібрані монети ви можете обміняти на реальні призи в нашому магазині",
        step3Btn: "Перейти до магазину",
        AndMoreTitle: "І багато іншого...",
        addMoreItems1Title: "Спортивна лінія на 14",
        addMoreItems1Title2: "видів спорту",
        addMoreItems1subTitle: "Великий вибір спортивних подій",
        addMoreItems2Title: "Прогнози в режимі реального часу",
        addMoreItems3Title: "Велике ком’юніті прогнозистів",
        addMoreItems4Title: "Слідкуй за прогнозами своїх друзів та експертів ",
        addMoreItems5Title: "Детальна статистика прогнозів",
        addMoreItems5SubTitle:
          "Широкий вибір статистичних даних ваших прогнозів",
        addMoreItems6Title: "Поширюйте прогнози у соц. мережах",
        popularPredictionTitle: "Найбільший список прогнозів на спорт",
        popularPredictionSubTitle:
          "Переглядайте прогнози інших корисутвачів та експертів",
        getBonusTitle1: "Capperauditor - cпортивна соціальна мережа: Прогнози на спорт, Статистика, Спортивне ком'юніті.",
        getBonusTitle2: "CAP монет  на свій рахунок",
        getBonusSubTitle:
          "Ми з нетерпінням чекаємо, щоб побачити, чого ви досягнете з Capperauditor. А тому приготували для Вас крутий бонус у розмірі 1000 CAP монет. Реєструйся прямо зараз! Твій бонус вже чекає на тебе!",
        getBonusBtn: "Отримати бонус",
        FrequentlyQuestions: "Поширені запитання",
        accodianTitle1: "Для кого підходить capperauditor ?",
        accodianSubTitle1:
          "Платформа ідеально підходить для наступних користувачів:",
        accodian1SubTitle1:
          " 1. Спортивні аналітики: вони можуть використовувати Capperauditor для відстеження статистики своїх прогнозів та збільшення своєї аудиторії.",
        accodian1SubTitle2:
          "2. Гравці, які роблять ставки на спорт для заробітку: за допомогою Capperauditor вони можуть ефективно відслідковувати статистику своїх ставок та покращувати  стратегії гри.",
        accodian1SubTitle3:
          "3. Любителі, які роблять прогнози на спорт, але не хочуть витрачати власні кошти: Capperauditor дозволяє їм насолоджуватися прогнозуванням без фінансового ризику.",
        accodianTitle2: "Capperauditor  - це букмекерська контора?",
        accodianSubTitle2:
          "Ні, це соціальна мережа, де ви можете розміщувати та ділитися власними прогнозами та переглядати прогнози інших користувачів. ",
        accodianTitle3: "Чи можу я робити ставки на спорт на сайті?",
        accodianSubTitle3: "Ні, ви можете робити лише безкоштовні прогнози.",
        accodianTitle4: "Платформа безкоштовна?",
        accodianSubTitle4: "Так, всі інструменти на сайті безкоштовні.",
        accodianTitle5: "Що таке CAP монети?",
        accodianSubTitle5: "CAP монети - це віртуальна валюта нашого сайту. ",
        accodianTitle6: "Чи потрібно мені купувати CAP монети ?",
        accodian6SubTitle1:
          "Ні, після реєстрації ви отримаєте бонус у розмірі ",
        accodian6SubTitle2:
          " CAP монет. Після цього ви зможете збирати безкоштовні монети щодня.",
        accodianTitle7: "Чи є на сайті можливість виграти гроші, призи?",
        accodianSubTitle7:
          "Так, чим успішніші ваші прогнози, тим більше CAP монет ви зможете отримати. Потім їх можна буде обміняти на реальні товари в нашому",
        accodianSubTitle8: "магазині.",
        ShowMorePredictions: "Показати більше прогнозів",
        reviewTitle1: "Сотні людей обожнюють ",
        reviewTitle2: "Capperauditor",
        TrustpilotScore: "Trustpilot оцінка",
        TrustpilotScoreSubTitle: "Незалежна оцінка користувачів",
        cookieTitle:
          "Ми використовуємо файли cookie для основних функцій сайту функцій сайту і для кращого розуміння як ви використовуєте наш сайт, щоб ми могли створити найкращий можливий досвіддля вас ❤. Використовуючи наш сайт, ви погоджуєтеся з нашою політикою використання файлів cookie",
        CookiePolicyTitle: "Політика Cookie",
        gotItBtn: "Добре, я зрозумів",
        or: "АБО",
        freeRegistrationEmail: "Зареєструватися через email",
      },
      tooltip: {
        Mustcontainatleast: "Повинно містити щонайменше",
        characters: "12 символів",
        lowercasecharacter: "1 маленьку букву",
        uppercasecharacter: "1 велику букву",
        number: "1 число",
        specialcharacter: "1 спеціальний символ",
      },
      SignIn: {
        tabTitle: "Увійти в Capperauditor",
        SignintoCapperauditor: "Увійти в Capperauditor",
        ContinuewithGoogle: "Продовжити з Google",
        ContinuewithFacebook: "Продовжити з Facebook",
        or: "або",
        Youremailorusername: "Ваш email або ім'я користувача",
        Enteryouremailusername: "Введіть свій email або ім’я користувача",
        Donthaveaccount: "Не маєте профілю?",
        SignUp: "Зареєструйтеся",
        Youremailorusernameisincorrect:
          "Ваш email або ім'я користувача невірні",
        isGoogleMsg: `У цей обліковий запис можна увійти лише за допомогою Google, або скинувши пароль за допомогою "Скинути пароль".`,
        isFaceBookMsg: ` У цей обліковий запис можна увійти лише за допомогою Facebook, або скинувши пароль за допомогою "Скинути пароль”.`,
        Continue: "Далі",
      },
      logIn: {
        tabTitle: "Увійти в Capperauditor",
        Welcome: "Вітаємо ",
        Changeemail: "Змінити",
        ChangeUserName: "Змінити",
        YourPassword: "Ваш пароль",
        Enteryourpassword: "Введіть пароль...",
        ForgotPassword: "Забули пароль?",
        ErrorMsg: `Ви ввели неправильний пароль, спробуйте ще раз або скористайтеся кнопкою "Скинути пароль”`,
        LogIn: "Увійти",
      },
      resetPassWord: {
        tabTitle: "Скинути пароль | Capperauditor",
        resendEmailTitle: "Скинути пароль",
        errorMsg: "Будь ласка, введіть правильний email",
        Email: "Email",
        decriptMsg:
          "Введіть свою електронну адресу нижче, за кілька хвилин ви отримаєте лист з інструкціями, як скинути пароль.",
        Email: "Email",
        Enteryouremailaddress: "Введіть ваш email",
        Resetpassword: "Скинути",
        setNewPassBtn: "Вставновити новий пароль",
        // reset new password
        newPasswordTabTitle: "Встановити новий пароль | Capperauditor",
        SetnewpasswordBtn: "Вставновити новий пароль",
        newPasswordTitle: "Встановити новий пароль",
        Newpassword: "Новий пароль",
        Enteryourpassword: "Введіть новий пароль...",
      },
      signUp: {
        tabTitle: "Безкоштовна реєстрація на Capperauditor",
        tabDecription:
          "Створіть акаунт на Capperauditor та отримайте 1000 бонусних CAP монет на свій рахунок.",
        title: "Створити профіль",
        subTitle: "Діліться, відстежуйте та аналізуйте свої спортивні прогнози",
        isGoogle: "Продовжити з Google",
        isFaceBook: "Продовжити з Facebook",
        or: "або",
        Youremail: "Email",
        Password: "Пароль",
        Continue: "Далі",
        Alreadyaccount: "Уже маєте профіль?",
        Login: "Увійдіть",
        Enteryourpassword: "Введіть пароль ...",
        PrivacyPolicyLine: "Реєструючись, ви погоджуєтеся з нашою",
        PrivacyPolicy: "Політикою конфіденційності",
        alreadyExistMsg: "Електронна пошта вже існує",
        InvalidMsg: "Недійсна електронна пошта ",
        fieldRequired: "Це поле є обов'язковим",
      },
      checkMail: {
        tabTitle: "Перевірте свою електронну пошту | Capperauditor",
        subTitle: "Перевірте свою електронну пошту",
        firstMsg: "Ми надіслали спеціальне посилання на ",
        secondMsg:
          "Перейдіть за посиланням у листі, щоб підтвердити свою електронну адресу.",
        Wrongemail: "Ввели неправильний email?",
        ReEnterAddress: "Змінити",
      },
      returnToLogin: {
        CheckemailTitle: "Перевірте свою електронну пошту",
        returnToLoginSubTitle1: "На email ",
        returnToLoginSubTitle2:
          "було надіслано лист з інструкціями щодо зміни паролю.",
        decriptionTitle: `Якщо лист не з'явиться найближчим часом, перевірте папку "Спам" або переконайтеся, що ви ввели ту саму адресу, яку використовували при реєстрації.`,
        returnToLoginBtn: "Повернутися до входу",
      },
      settingUpAccount: {
        subTitle: "Відстежуйте, аналізуйте, покращуйте свої ставки",
      },
      register: {
        tabTitle: "Завершити налаштування профілю | Capperauditor",
        title: "Завершити налаштування профілю",
        subTitle: "Діліться, відстежуйте та аналізуйте свої спортивні прогнози",
        name: "Ім’я",
        namePlaceHolder: "Введіть своє ім'я",
        LastName: "Прізвище",
        lastNameHolder: "Введіть своє прізвище",
        Username: "Ім'я користувача",
        UsernamePLaceHolder: "Наприклад capperauditor",
        registerBtn: "Завершити налашутвання",
        alreadyExistMsg: "Це ім'я користувача вже зайнято",
        Namemin3Max15ErrorMsg: "Ім'я користувача має містити від 3 до 18 літер",
        lastmin3Max15ErrorMsg: "Прізвище має містити від 3 до 18 літер",
        NameonlyLettersMsg: "Ім'я не може містити символів",
        userNameonlyLettersMsg: "Прізвище не може містити символів",
        min5Max15ErrorMsg: "Ім'я користувача має містити лише англійські букви",
        lengthCountMsg: "Ім'я користувача має містити від 5 до 20 літер",
        noSymbollOnlyCharMsg: "Ім'я користувача не може містити символів",
        fieldRequired: "Це поле є обов'язковим",
      },
      error: {
        avatarContent: "Формат зображення не підтримується.",
        avatarSize: "Розмір зображення перевищує 4 Мб.",
        avatarDefault: "JPG або PNG. Максимальний розмір 4 МБ.",
        avatarDeleteBtn: "Видалити зображення",
        avatarDeleteMsg: "Ви впевнені, що хочете видалити своє зображення?",
        avatarDeleteConfirm: "Так, видалити",
        avatarDeleteDiscard: "Ні",
        confirmMsg: "Так",
      },
      profile: {
        oddsTooltipMsg:
          "Цей прогноз містить подію, яка була повернута.Перше число - початковий  коефіцієнт, друге - кінцевий коефіцієнт, буде зарахований до статистики.",
        Averageodd: "Середній коефіцієнт",
        Shapedata: "Форма",
        Latest5predictions: "Останні 5 прогнозів",
        Latest: "Крайній",
        alertCircleTooltipMsg:
          "Форма користувача на основі останніх 5 прогнозів",
        searchBarPlaceHolder: "Пошук події або прогнозиста",
        YourSportLine: "Ваша спортивна лінія",
        followers: "Читачі",
        follow: "Стежити",
        unfollow: "Не стежити",
        following: "Відстежуються",
        followingBtn: "Слідкування",
        predictions: "Прогнози",
        noFollowersText:
          "Тут будуть відображені прогнозисти, як тільки хтось почне слідкувати за вами.",
        noFollowersTitle: "Читачі які слідкують за вами",
        noFollowingText:
          "Тут будуть відображені прогнозисти, як тільки ви почнете стежити за ними.",
        noFollowingTitle: "Прогнозисти, за якими ви слідкуєте",
        analytics: "Статистика",
        noPredictionYetProfile: "Ви ще не опублікували жодного прогнозу",
        noPredictions: "Користувач ще не опублікував жодного прогнозу",
        addPredictions: "Додати прогноз",
        editProfile: "Редагувати профіль",
        addYourBio: "+ Розкажіть про себе",
        bio: "Про себе",
        saveCancel: "Скасувати",
        saveConfirm: "Зберегти",
        shareProfile: "Поширити профіль",
        ShareSocialMedia: "Поділитися в соціальних мережах",
        linkToShare: "Посилання, щоб поділитися",
        copyLink: "Копіювати",
        copied: "Скопійовано",
        selectSport: "Вибрати спорт",
        search: "Пошук",
        allLeagues: "Всі турніри",
        popularLeagues: "Популярні турніри",
        LEAGUESBYCOUNTRY: "ЛІГИ ЗА КРАЇНАМИ",
        selectSport: "Вибрати спорт",
        showOddsLine: "Показати лінію",
        countries: "Країни",
        selectLeague: "Вибрати турнір",
        titleAddPrediction: "Додати прогноз",
        AddSport: "Додати спорт",
        ManageSports: "Керувати спортом",
        slipEmptyMsg: "Твій купон порожній",
        addBitSlip:
          "Перегляньте лінію і виберіть прогнози, натиснувши на коефіцієнт.",
        allTab: "Всі",
        allLive: "Live",
        allSheduled: "Заплановані",
        ExitManaging: "Вихід з керування",
        DeleteSport: "Видалити спорт",
        deleteSportMsg: "Ви впевнені, що хочете видалити",
        EditLeagues: "Редагувати список турнірів",
        Parlay: "Експрес",
        notificationParlay: "Експрес",
        events: "події",
        ClearAll: "Очистити купон",
        TotalOdds: "Загальний коефіцієнт:",
        PredictionDescription: "Опис",
        PlacePrediction: "Додати прогноз",
        deleteSportNotificationMsg:
          "The <span>{{selectedSportName}}</span> було видалено",
        DoubleChance: "Подвійний шанс",
        FullTimeResult: "Повний результат",
        Total: "Тотафл",
        Over: "Більше",
        Under: "Менше",
        Handicap: "Фора",
        RealMadridTotal: "Реал Мадрид Тотал",
        Teamtoscorethegoal: "Команда заб'є гол №",
        Chelse: "Челсі",
        None: "Нічия",
        RealMadrid: "Реал Мадрид",
        Bothteamstoscore: "Обидві команди заб'ють",
        Correctscore: "Точний рахунок",
        Single: "Ординар",
        Notenoughdata7Days: "Прогнозів за останній тиждень недостатньо.",
        Notenoughdata1Month: "Прогнозів за останній місяць недостатньо.",
        Notenoughdata3Month: "Прогнозів за останній 3 місяці недостатньо.",
        Notenoughdata6Month: "Прогнозів за останній 6 місяців недостатньо.",
        Notenoughdata1Year: "Прогнозів за останній 1 рік недостатньо.",
        NotenoughdataAllYear: "Прогнозів за цей період недостатньо.",
        tryAgainLine: "Будь-ласка спробуйте пізніше",
        Notdatadisplay: "Недостатньо даних для відображення",
        textAreaPlaceHolder: "Розкажіть коротко про себе",
        Top3predictions: "Топ-3 прогнози",
        Event: "Подія",
        Predictiontype: "Прогноз",
        Odds: "Коеф.",
        TopPrediction:
          "Це експрес-прогноз. Натисніть,щоб відкрити прогноз повністю",
        PredictionDetails: "Деталі прогнозу",
        Postedon: "Опубліковано ",
        TopPredictionEmptyData: "Прогнозів ще немає",
        TopPredictionEmptySubTitle: "Будь ласка, спробуйте пізніше",
      },
      chartSummary: {
        titleBarWon: "Перемога",
        titleBarLost: "Поразка",
        titleBarReturns: "Повернення",
        Balance: "Поточний баланс",
        CollectedCoins: "Зібрано монет",
        Odds: "Коефіцієнт",
        tooltipLosses: "Поразок",
        tooltipReturns: "Повернень",
      },

      predictionFilter: {
        filterWon: "Перемога",
        filterReturned: "Повернення",
        MoreBtn: "Більше",
      },
      navbar: {
        myProfile: "Мій профіль",
        feed: "Прогнози",
        Leaderboard: "Рейтинг",
        Giveaways: "Подарунки",
        AdminPanel: "Адмінка",
        profile: "Профіль",
        settings: "Налаштування",
        logout: "Вийти",
        notification: {
          Notifications: "Сповіщення",
          pendingNotifications: "непрочитаних сповіщень",
          Clearall: "Очистити все",
          ViewAll: "Дивитися всі",
          Nonewnotifications: "Немає нових сповіщень",
          NotificationsTitle: "Сповіщення",
          All: "Всі",
          Predictions: "Прогнози",
          Usersactivity: "Активність користувачів",
          Nodatayet: "Сповіщень поки що немає.",
          Previous: "Попередня",
          Next: "Наступна",
        },
        confirmModel: {
          firstTitle: "Створіть аккаунт та отримайте бонус",
          subTitle1: "Зареєструйтесь прямо зараз та отримайте",
          subTitle2: "CAP монет",
          subTitle3: "на свій рахунок.",
          confirmModelBtn: "Зареєструватись та отримати бонус",
        },
        Marketplace: "Магазин",
      },
      setting: {
        metaTitle: "Налаштування",
        settings: "Налашутвання",
        general: "Загальні",
        profile: "Профіль",
        security: "Безпека",
        language: "Мова",
        currency: "Валюта",
        oddsFormat: "Формат коефіцієнтів",
        saveChanges: "Зберегти зміни",
        changesSaved: "Зміни збережено",
        name: "Ім'я",
        username: "Ім'я користувача",
        email: "Адреса електронної пошти",
        currentPassword: "Поточний пароль",
        newPassword: "Новий пароль",
        confirmPassword: "Підтвердьте пароль",
        successedMessage: "Ви успішно оновили свій пароль",
        currentPasswordWrong: "Ви ввели неправильний пароль",
        passwordMismatch: "Паролі не збігаються",
        verifyEmailTitle: "Підтвердіть новий email",
        verifyEmailContaint:
          "Ми надіслали електронний лист на адресу <span class=verify-email>{{email}}</span>.Зміни не будуть завершені, доки ви не підтвердите свою нову адресу електронної пошти.",
        cancelChange: "Скасувати",
        resendEmail: "Повторно надіслати",
        emailError: "Така ектронна пошта вже існує",
        nameUpdatedMessage: "Ім'я успішно оновлено",
      },
      feed: {
        metaTitle:
          "Безкоштовні прогнози на спорт: ставки на сьогодні та завтра | Capperauditor",
        metaDescription:
          "Отримайте доступ до більше як {{count}} безкоштовних прогнозів на 14+ видів спорту. ✓ Актуальні прогнози на сьогодні та завтра.",
        metaSocialTitle: "Безкоштовні прогнози на спорт | Capperauditor",
        Parlay: "Експрес",
        predictionTab: "Прогнози на спорт | Прогнози на футбол | Capperauditor",
        predictionMetaTitle:
          "Прогнози на спорт | Прогнози на футбол | Capperauditor",
        predictionMetaDescription:
          "Отримайте безкоштовні прогнози на футбол, баскетбол, хокей та інші види спорту від експертів.",
        lockSearchBarMsg:
          "Пошук доступний лише зареєстрованим учасникам Capperauditor",
        lockFilterMsg:
          "Фільтрація доступна лише зареєстрованим учасникам Capperauditor",
        Recomended: "Для тебе",
        Filters: "Фільтри",
        predictionsFound: "прогнозів знайдено",
        SortBy: "Сортувати за",
        Newest: "Часом додавання",
        Popular: "Популярністю",
        Odds: "Коефіцієнтом",
        Odd: "Коеф.",
        PopularEvents: "Популярні події",
        Sport: "Спорт",
        Tournament: "Турнір",
        PredictionType: "Тип передбачення",
        Status: "Статус",
        OddsRange: "Діапазон коефіцієнтів",
        ClearFilters: "Очистити всі фільтри",
        Apply: "Застосувати",
        Makeprediction: "Зробити прогноз",
        NoResultsFound: "Нічого не знайдено",
        alertMsg: "Спочатку виберіть вид спорту",
        Pending: "В очікуванні",
        Won: "Перемог",
        win: "Перемог",
        Lost: "Поразка",
        Returned: "Повернень",
        panding: "В очікуванні",
        Prediction: "Прогноз",
        socialCommunity:
          "Поширте цей прогноз в соціальних мережах або завантажте як зображення",
        PredictionDate: "Дата та час прогнозу",
        joinCapperauditore:
          "Відскануйте QR-код, щоб відкрити сторінку прогнозів",
        Multiple: "Експрес",
        Summary: "Зведення",
        Alltournaments: "Турнір",
        Allupcoming: "Період часу",
        Addtournament: "Додати турнір",
        Today: "Сьогодні",
        Tomorrow: "Завтра",
        ThereAreNoMatches: " Подій не знайдено",
        tournamentOrDay:
          "Заходи не плануються. Перевірте пізніше або оберіть інший турнір чи день.",
        More: "більше",
        Detailedstatistics: "Детальна статистика",
        Generalstatistics: "Загальна статистика",
        D7: "7Д",
        m1: "1M",
        m3: "3M",
        m6: "6M",
        y1: "1Р",
        All: "Весь час",
        statusWon: "Перемог",
        statusLost: "Поразка",
        modelWon: "Перемога",
        modelLost: "Поразка",
        modelReturn: "Повернення",
        modelPending: "В очікуванні",
        statusReturn: "Повернень",
        dropDownReturned: "Повернення",
        statusPending: "В очікуванні",
        shareModelPrediction: "Прогнозів",
        ModelstatusWon: "Виграш",
        ModelstatusLost: "Програш",
        ModelstatusReturn: "Повернення",
        ModelstatusPending: "В очікуванні",
        And: "І",
        morePredictions: "більше-передбачення",
        betSlipTextAreaPlaceHolder: "Коротко опишіть ваш прогноз...",
        ShowMorePredictions: "Показати більше подій",
        showMessage: "Ви дійшли до кінця списку",
        OpenPrediction: "Відкрити прогноз",
        StakedCoins: "Поставлено монет",
        PossibleWin: "Можливий виграш",
        Type: "Тип",
        Status: "Статус",
        PreGame: "Перед грою",
        TotalOdds: "Коефіцієнт",
        tooltipMsg:
          "Цей прогноз містить подію, яка була повернута. Перше число – початковий коефіцієнт, друге – кінцевий коефіцієнт, який буде включено до статистики.",
        DeleteUser: "Видалити",
        edit: "Редагувати",
        EditPredictionModelTitle: "Редагувати прогноз",
        DeletePredictionTitle: "Видалити передбачення",
        DeletePredictionMessage: "Ви впевнені, що хочете видалити прогноз?",
        PredictionStatus: "Статус",
        Score: "Оцінка",
        succesTooltipMsgTitle: "Ваш прогноз успішно опубліковано",
        succesTooltipMsgSubTitle:
          "Ви можете перейти до свого прогнозу, натиснувши кнопку нижче",
        myPredictionBtn: "Переходьте до мого передбачення",
        ErrorTooltipMsgTitle: "Щось пішло не так",
        ErrorTooltipMsgsubTitle: "Ставки призупинено, повторіть спробу",
        errorCode3: "Результат не існує.",
        errorCode99: "Невідома помилка",
        errorCode1: "Неможливо отримати підсумки або дані пошкоджені.",
        ErrorCode0: "добре. Купон приймається.",
        ErrorMainTitle: "Щось пішло не так",
      },
      homePage: {
        metaTitle: "Безкоштовна платформа для каперів та уболівальників.",
        metaDescription:
          "Публікуйте, діліться, відстежуйте та аналізуйте власні спортивні прогнози, а також прогнози ваших друзів та експертів.",
        Predictions: "Прогнози",
        LogIn: "Увійти",
        account: "Зареєструватися",
        resourcesTitle:
          "Усі ресурси, необхідні для покращення та контролю ваших спортивних прогнозів",
        HandicappersSports:
          "Соціальна мережа для спортивних аналітиків та прогнозистів",
        HandicappersSportsDecription:
          "Робіть безкоштовні прогнози на спорт, відслідковуйте статистику, заробляйте монети та обмінюйте їх на реальні призи.",
        getStartedButton: "Реєстрація за допомогою Email",
        DifferentSportsTitle: "14 видів спорту",
        DifferentSportsContains:
          "Робіть прогнози на вид спорту, який вам подобається.",
        LargeCommunity: "Спільнота однодумців",
        LargeCommunityContains:
          "Знайдіть своїх друзів, однодумців, та діліться прогнозами один з одним.",
        DetailedAnalytics: "Детальна статистика",
        DetailedAnalyticsContains:
          "Відстежуйте, наскільки успішними є ваші прогнози в режимі реального часу.",
        Realtimesportsdataodds: "Реальні коефіцієнти",
        RealtimesportsdataoddsContains:
          "Реальні коефіцієнти, що змінюються в реальному часі.",
        BiggestLineofevents: "Велика лінія спортивних подій",
        BiggestLineofeventsContains:
          "Одна з найбільших спортивних лінійок, де ви точно знайдете потрібний вам турнір, команду.",
        largestPredictions: "Найбільший список прогнозів",
        largestPredictionsContains:
          "Слідкуйте за прогнозами на ваші улюблені матчі, поєдинки.",
        BrowsePredictionsTitle: "Прогнози",
        BrowsePredictions1Quotes: "Публікуйте та діліться прогнозами з іншими.",
        BrowsePredictions2Quotes:
          "Слідкуйте за прогнозами своїх друзів, експертів.",
        BrowsePredictions3Quotes: "Діліться прогнозами в соціальних мережах",
        ButtonPrediciton: "Переглянути  прогнози ",
        Analytics: {
          Statistics: "Статистика",
          GeneralStatistics: "Загальна статистика:",
          Wins: "Перемог",
          Losses: "Поразок",
          Returns: "Повернень",
          DetailedStatisticsfor: "Детальна статистика прогнозів за:",
          Week: "Тиждень",
          Month: "Місяць",
          Months3: "3 місяці",
          Months6: "6 місяців",
          Year: "Рік",
          AllTheTime: "Весь час",
          ButtonCheckOut: "Почати безкоштовно",
        },
        SportsEvents: {
          SpecialList: "Індивідуальна спортивна лінія",
          Maang:
            "Налаштовуйте спортивну лінію за власними потребами. Обирайте цікавий для вас вид спорту і турнір.",
          Frameworks: "Відфільтровуйте нецікаві турніри та лишайте улюблені.",
        },
        FavoriteSport: {
          Sports: "Переглядайте прогнози на ваш улюблений вид спорту",
          ButtonAllSports: "Переглянути всі прогнози на спорт",
          ShowMoreSports: "Покажіть більше спорту",
          ShowLessSports: "Показуйте менше спорту",
        },
        CreateAccount: {
          SharePrediciton:
            "Створіть обліковий запис і опублікуйте свій перший прогноз",
          LoremIpsum:
            "Платформа спортивних прогнозів пропонує вам поділитися власними прогнозами, відстежувати статистику, аналізувати результати та переглядати прогнози ваших друзів та експертів.",
          ButtonStarted: "Почніть БЕЗКОШТОВНО",
        },
        Community: {
          CommunitySaying: "Про нас говорять",
        },
        FAQ: {
          FreqeuntlyQuestions: "Поширені запитання",
          DesignState: "Capperauditor - це букмекерська контора?",
          ProductManagement:
            "Ні, це соціальна мережа, де ви можете розміщувати та ділитися  власними прогнозами та переглядати прогнози інших користувачів.",
          Exercises: "Чи можу я робити ставки на сайті?",
          completelyFree:
            "Ні, ви можете робити лише прогнози, які абсолютно безкоштовні.",
          platform: "Платформа безкоштовна?",
          platformFeatures: "Так, всі інструменти на сайті безкоштовні.",
          user1name: "Влад Мирошниченко",
          user1nameContains:
            "Я дуже задоволений, що відкрив для себе дану платформу, тут я можу бачити прогнози інших користувачів на мої улюблені матчі. Це дійсно цікаво та корисно. 😍",
          user4name: "Назар Мельничук",
          user4nameContains:
            "Ще один плюс платформи - це зручний та простий інтерфейс. Я легко можу знайти необхідну інформацію та взаємодіяти з іншими користувачами.",
          user7name: "Віталій Абрамчук",
          user7nameContains:
            "Я ціную можливість бачити  прогнози та статистику інших користувачів. Це дозволяє мені бачити різні точки зору та робити більш обдумані прогнози.🔥",
          user2name: "Андрій Шолох",
          user2nameContains:
            "Я вже давно шукав таку платформу, де я міг би бачити прогнози та статистику інших людей. З capperauditor це зручно та легко.🔥🔥",
          user5name: "Катерина Андрієнко",
          user5nameContains:
            "Я впевнений, що capperauditor є найкращою платформою для тих, хто хоче бути в курсі спортивних подій та робити вірні прогнози.🔥🔥",
          user8name: "Владислав Шпак",
          user8nameContains:
            "Я вважаю, що capperauditor - це відмінна платформа для тих, хто хоче збільшити кілікість успішних прогнозів.",
          user3name: "Олександр Ткаченко",
          user3nameContains:
            "Я люблю спорт, тому capperauditor є для мене ідеальним місцем, де я можу отримати всю необхідну інформацію про матчі та зробити правильний прогноз.",
          user6name: "Валерій Боженко",
          user6nameContains:
            "Як людина, яка любить аналізувати дані, я дуже вражений тим, які дані надає capperauditor. Це дійсно допомагає мені зробити правильний прогноз.",
          user9name: "Євгенія Симоненко",
          user9nameContains:
            "Я дуже радий, що capperauditor надає можливість створювати свої власні прогнози та ділитися ними з іншими користувачами. Це дійсно допомагає розвиватися та вчитися.",
        },
        homePageFooter: {
          AllInOne:
            "Безкоштовно публікуйте прогнози на спорт та вигравайте реальні призи",
          SecondDecription:
            "Ми не маємо жодного стосунку до ігрового бізнесу. Усі інструменти, які існують на сайті є безкоштовними і не мають фінансових операцій.",
          Navigation: "Навігація",
          GetInTouch: "Контакти",
          FollowUs: "Підпишіться на нас",
          Predictions: "Прогнози",
          PrivacyPolicy: "Політика конфіденційності",
          Cookies: "Cookie",
          AllRightsReserved: "Всі права захищені.",
        },
        homePrediction: {
          SignIn: "Увійдіть у Capperauditor",
        },
      },
      admin: {
        Dashboard: "Огляд",
        Users: "Користувачі",
        Hello: "Привіт",
        checkStatsToday: "Давайте сьогодні перевіримо статистику capperauditor",
        checkUserToday: "Давайте оглянемо користувачів capperauditor",
        adminSearchPlaceHolder: "Пошук за іменем, username або email ",
        NewUsersToday: "Нові користувачі сьогодні",
        usersfound: "знайдено користувачів",
        NewpredictionsToday: "Нові прогнози сьогодні",
        totalUsers: "всього користувачів",
        totalPredictions: "Всього прогнозів",
        userTable: {
          user: "Користувач",
          Country: "Країна",
          Email: "Email",
          Status: "Статус",
          SRate: "Рейт.",
          Pred: "Прогн.",
        },
        PendingBtn: "Очікує",
        ActivatedBtn: "Активний",
        Changeuserpermission: "Змінити тип користувача",
        DeleteUser: "Видалити користувача",
        SeeAll: "Весь список",
        SeeAllUsers: "Переглянути всіх користувачів",
        sureityMsg: "Ви впевнені, що хочете видалити користувача?",
        ChangeUserPermission: "Змінити тип користувача",
        Admin: "Адмін",
        SingleUser: "Користувач",
        Loadmoreusers: "Завантажити більше користувачів",
        UserSettings: "Налаштування користувача",
        GeneralsettingsTitle: "Загальні налаштування",
        GeneralsettingsSubTitle:
          "Давайте сьогодні перевіримо статистику capperauditor",
        Signupreward: "Нагорода за реєстрацію",
      },
      notificationMOdel: {
        likeDecriptMsg: "вподобав ваш прогноз",
        dislikeDecriptMsg: "не вподобав ваш прогноз",
        followingMsg: "почав слідкувати за вами",
      },
      giveAways: {
        metaTitle: "Турніри. Великі нагороди за точні прогнози",
        metaDecription:
          "Відгадуйте точні рахунки в різних матчах та вигравайте CAP монети. Участь безкоштовна.",
        giveAwaysContent:
          "Робіть безкоштовні прогнози та вигравайте реальні гроші",
        CreateGiveawaybtn: "Створіть роздачу",
        Participants: "Учасники",
        Reward: "Нагорода",
        emptyMsg: "Учасників ще немає",
        JoinGiveawayBtn: "Приєднуйтесь до розіграшу",
        Joined: "Приєднався",
        Calculation: "Розрахунок",
        Winner: "Переможець",
        GiveawayIsFinished: "Роздача завершена",
        SearchLeagueSearch: "Пошук ліги чи країни",
        Bycountry: "За країною",
        SelectMatch: "Виберіть збіг",
        Searchteamname: "Назва пошукової групи",
        Sеtanaward: "Призначити нагороду",
        ie: "тобто 1000 грн",
        Numberofwinners: "Кількість переможців",
        CreateGiveawayBtn: "Створіть роздачу",
        RulesTitle: "правила",
        Rules: {
          firstRule:
            "Якщо кількість користувачів, які зробили правильний прогноз, перевищує максимально допустиму кількість переможців, наш алгоритм випадковим чином вибере переможців.",
          secondRule:
            "Якщо ви виграєте, після закінчення розіграшу ви отримаєте електронний лист із подальшими інструкціями.",
          thirdRule:
            "Якщо ми не отримаємо від вас відповіді протягом 3 днів після закінчення розіграшу, ваш приз буде втрачено",
        },
        TakePartTitle: "Бери участь у розіграші",
        TakePartSubTitle:
          "Щоб взяти участь у розіграші, виконайте наведені нижче дії",
        socialMedia: "Слідкуйте за нами в соціальних мережах",
        socialMediaSubTitle:
          "Слідкуйте за нами в соціальних мережах і підтвердьте свою підписку",
        ConfirmSubscriptionBtn: "Підтвердити підписку",
        makeprediction: "Зробіть свій прогноз",
        makePredictionSubTitle:
          "Виберіть правильний бал, який, на вашу думку, є найбільш імовірним",
        ConfirmSubscription: {
          ConfirmSubscriptionTitle: "Підтвердити підписку",
          ConfirmSubscriptionSubTitle:
            "Будь ласка, вкажіть своє ім’я користувача в наведених нижче соціальних мережах, щоб ми могли перевірити, чи ви стежите за нами.",
          EditUsernamesTitle: "Редагувати імена користувачів",
          SaveUsernamesBtn: "Зберегти імена користувачів",
        },
        MakepredictionBtn: "Додати прогноз",
        EditUsernamesBtn: "Редагувати імена користувачів",
        predictions: {
          predictionsTitle: "Прогнози",
          predictionsSubTitle: "Прогнози учасників",
          JoinGiveawayBtn: "Приєднуйтесь до роздачі",
        },
        Winner: {
          WinnerTitle: "Переможець",
          WinnerSubTitle: "Вітаємо переможця(ів)",
        },
        PerfectPredictors: {
          PerfectPredictorsTitle: "Ідеальні провісники",
          PerfectPredictorsSubTitle:
            "Інші учасники, які вгадали точний рахунок",
          onlyTwoPerfectPredictorsLine:
            "Інших учасників, які вгадали правильний рахунок, немає",
          noPerfectPredictors:
            "На жаль, точної оцінки ніхто не вгадав, тож чекайте на нові розіграші",
        },
        AllPredictions: {
          AllPredictionsTitle: "Всі прогнози",
          AllPredictionsSubTitle: "Всі прогнози, зроблені учасниками",
        },
        ParticipantsTable: {
          UserTitle: "Користувач",
          InstagramTitle: "Instagram",
          TikTokTitle: "TikTok",
          ConfirmwinnerTitle: "Підтвердити переможця",
        },
      },
      sportLine: {
        sportlineTitle: "Спортивна лінія",
        MoreLabel: "більше",
        PredictionSlip: "Бланк прогнозу",
        newBetslipTitle: "Купон прогнозу",
        newBetslipsubTitle: "Ваш купон порожній",
        seeAll: "Всі події",
        AllCompetitions: "Всі ліги",
        BetTotal: "Сумма",
        Minimum10: "Мінімум 10",
        Sports: "Оберіть вид спорту",
        Competitions: "Обрати лігу",
        Available: "Доступно",
        max: "Макс",
        bottomTextTitle:
          "Спортивна лінія: коефіцієнти на футбол, бокс та інші види спорту",
        bottomTextDescription:
          "Актуальні коефіцієнти на футбол, хокей, бокс та інші види спорту!  Доступні live коефіцієнти на понад 14 видів спорту.",
        HowCoinsDescription: "Як заробити безкоштовні монети CAP?",
        LearnMore: "Вивчайте більше",
        AddDescription: "Додайте опис",
        optional: "Необов'язково",
        minimumStake: "Мінімальна ставка становить 10 CAP",
        notEnoughFund: "Недостатньо коштів на вашому рахунку. Ваш баланс",
        metaTitle:
          "Коефіцієнти на спорт: зробити ставку на футбол, бокс | Capperauditor",
        metaSocialTitle:
          "Коефіцієнти на спорт: зробити ставку на футбол, бокс…",
        metaDescription:
          "Актуальні коефіцієнти на футбол, хокей, бокс та інші види спорту! ► Доступні live коефіцієнти на понад 14 видів спорту.",
      },
      wallet: {
        WalletTitle: "Гаманець",
        metaTitle: "Мій баланс",
        MyCAPcoinsTitle: "Мої CAP монети",
        MyCAPcoinsSubTitle:
          "Монети Capperauditor (CAP) - це офіційна валюта нашого сайту, яка надається безкоштовно. Збирайте їх, заробляйте і обмінюйте на реальні призи у нашому магазині.",
        Balance: "Монети",
        coinsLastWeek: "монет за останній тиждень",
        CollectedCoins: "Зібрані монети",
        Collect: "Збирати",
        amount: "Сума",
        EarnedCoins: "Зароблені монети",
        collectCoinEveryDayTitle: "Збирайте CAP монети щодня",
        collectCoinEveryDaySubTitle:
          "Збирайте монети щодня і ваша нагорода зростатиме.",
        CollectCoinsBtn: "Зібрати монети",
        CollectedBtn: "Зібрано",
        toCollectBtn: "до збору",
        mobileCollectCoin: "Зібрані",
        mobileEarnCoin: "Зароблені",
        mobileSpanCoin: "Витрачені",
        EarnCollectedCoinsTab: "Зібрані монети",
        EarnedCoinsTab: "Зароблені монети",
        EarningHistoryTitle: "Історія",
        EarningHistorySubTitle:
          "Історія зібраних, зароблених та витрачених монет.",
        Activity: "Активність",
        CoinsAmount: "Кількість монет",
        Date: "Дата",
        Event: "Прогноз",
        StakedCoins: "Поставлено монет",
        Odd: "Коефіцієнт",
        EarnedCoins: "Зароблено монет",
        modelTitle: "Ура, ви зібрали",
        coins: "монет",
        comeBackMessage: "Приходьте завтра, та отримайте ще більше монет.",
        OkThanks: "Добре, дякую",
        noRewardTitle: "Історія досягнень пуста",
        noRewardSubTitle:
          "Як тільки ви здобудете досягнення, вони всі з’являться тут.",
        noPostDataTitle: "Ви ще не опублікували жодного прогнозу",
        noPostDataSubTitle:
          "Після того, як ви зробите свій перший прогноз, тут з’явиться історія ваших зароблених монет",
        titleAddPrediction: "Додати прогноз",
        showMore: "Показати більше",
        Day: "День",
        eachNextDay: "Кожен наступний день",
        SignUpreward: "Нагорода за реєстрацію",
        SpentCoins: "Витрачено монет",
        Purchase: "Придбання",
        emptymainTitle: "У вас ще немає покупок",
        emptyMainSubtitle:
          "Після того, як ви зробите першу покупку, тут з’явиться ваша історія витрат",
        emptyStateBtn: "Перейти до магазину",
      },
      Marketplace: {
        metaTitle: "Магазин: Обмін монет на реальні призи | Capperauditor",
        metaSocialTitle: "Магазин Capperauditor",
        metaDescription:
          "Отримуйте реальні призи за успішні прогнози: Футбольний м'яч, Павербанк, PlayStation та багато іншого ✦ Накопичуйте монети за успішні прогнози та обмінюйте їх на реальні товари в магазині Capperauditor.",
        MarketplaceTitle: "Магазин Capperauditor",
        MarketplaceSubTitle:
          "Ласкаво просимо до магазину Capperauditor, де ваші CAP монети отримують реальну цінність! Ознайомтеся з широким асортиментом товарів, які ви можете придбати за CAP монети.",
        costTitle: "Вартість",
        BuyNowBtn: "Купити зараз",
        editProductTooltip: "Редагувати товар",
        deleteProductTooltip: "Видалити товар",
        soldOutBtn: "ПРОДАНО",
        addProductBtn: "Додати новий товар",
        purchaseSuccessfullMsg: "Покупку успішно завершено",
        purchaseMsg1: "Ви успішно здійснили покупку",
        purchaseMsg2:
          ". Усі деталі щодо отримання замовлення надіслано на вашу електронну пошту",
        purchaseMsg3:
          "Якщо ви не отримали сповіщення поштою, напишіть в нашу службу підтримки",
        DeleteProductTitle: "Видалити товар",
        DeleteProductMessage: "Ви впевнені, що хочете видалити продукт",
        addProductTitle: "Додати новий товар",
        addModelTitle: "Назва",
        PriceTitle: "Ціна",
        Status: "Статус",
        DeletePhoto: "Видалити фото",
        ProductPlaceHolder: "Назва товару",
        PricePlaceHoldrer: "наприклад 20 000",
        statusPlaceHolderActive: "Активний",
        statusPlaceHolderSoldout: "Продано",
        UploadPhotoBtn: "Завантажити фото",
        AddProduct: "Додати товар",
        DeletephotobTN: "Видалити фото",
        onluNumricValueMsg: "Очікується ряд.",
        disableBtnTooltip:
          "У вас недостатньо монет, щоб зробити покупку. Щоб дізнатися, як заробити більше монет, натисніть",
        toolTipHear: "тут",
        EditPoroduct: "Редагувати товар",
      },
      Leaderboard: {
        metaTitle:
          "Топ безкоштовних капперів: Рейтинг найкращих прогнозистів | Capperauditor",
        metaDescription:
          "Рейтинг найкращих прогнозистів з детальною статистикою їх прогнозів! ★ Більше {{count}} успішних капперів.",
        metaSocialTitle: "Рейтинг найкращих прогнозистів | Capperauditor",
        Title: "Рейтинг найкращих прогнозистів",
        SubTitle: "Найкращі прогнозисти на capperauditor",
        signupTitle: "Зареєструйтесь і отримайте повний доступ до рейтингу.",
        signupText:
          "Приєднуйтесь прямо зараз і отримайте 1000 бонусних CAP монет на ваш рахунок",
        signupBtn: "Приєднатися",
        Grid: {
          title: "Таблиця лідерів",
          dropdown: {
            allTime: "За весь час",
            week: "Тиждень",
            month: "Місяць",
          },
          tableHeaders: {
            predictor: "Прогнозист",
            predictions: "Прогнозів",
            avg: "Сер. кф.",
            spc: "КУП",
            sport: "Спорт",
            coins: "CAP Монет",
          },
          tableHeaderHints: {
            avg: "Середній коефіцієнт успішних прогнозів",
            spc: "Коефіцієнт успішних прогнозів ( КУП ) - це відсоток успішних прогнозів відносно поразок",
            sport: "Вид спорту на який зроблено найбільше прогнозів",
          },
        },
      },
    },
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "ua", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option
    fallbackLng: ["ua"],
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export async function dynamicActivate(language) {
  if (!language) {
    if (localStorage.getItem("language")) {
      language = localStorage.getItem("language");
    } else if (localStorage.getItem("profile")) {
      language = apiService.getProfileFromStorage().Language;
    } else {
      language = "ua";
    }
  }

  await i18n.changeLanguage(language);
}

export default i18n;
