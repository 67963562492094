import React from "react";
import { useEffect } from "react";
import HomePageFooter from "../homepage/HomePageFooter/HomePageFooter";
import HomePageHeader from "../homepage/HomePageHeader/HomePageHeader";
import "./CookiePolicy.css";
import { Helmet } from "react-helmet";
const CookiePolicy = () => {
  useEffect(() => {
    document.body.classList.add("feed-containt");
  }, []);
  return (
    <>
      <Helmet>
        <link
          rel="alternate"
          href="https://capperauditor.com/cookie"
          hreflang="uk"
        />{" "}
        <link
          rel="alternate"
          href="https://capperauditor.com/en/cookie"
          hreflang="en"
        />{" "}
        <link
          rel="alternate"
          href="https://capperauditor.com/cookie"
          hreflang="x-default"
        />
      </Helmet>
      <HomePageHeader />
      <section>
        <div className="cookie-wrapper">
          <div className="cookie-wrapper-container">
            <div className="CookiePolicy-contains">
              <div className="CookiePolicy-title pb-3">Cookie Policy</div>
              <div className="Updated-date">Updated at 2023-02-01</div>
            </div>
          </div>
        </div>
        <div className="cookie-wrapper-bottom-container">
          <div className="cookie-wrapper-inner-container">
            <div className="Definitions-and-key-terms-title">
              Definitions and key terms
            </div>
            <div className="Definitions-decription pb-3">
              To help explain things as clearly as possible in this Cookie
              Policy, every time any of these terms are referenced, are strictly
              defined as:
            </div>
            <div className="d-flex cookies-sub-terms pb-3">
              <span className="Cookie-terms">●</span>
              <div className="Cookie-terms">
                Cookie: small amount of data generated by a website and saved by
                your web browser. It is used to identify your browser, provide
                analytics, remember information about you such as your language
                preference or login information.
              </div>
            </div>
            <div className="d-flex cookies-sub-terms pb-3">
              <span className="Cookie-terms">●</span>
              <div className="Cookie-terms">
                Cookie: small amount of data generated by a website and saved by
                your web browser. It is used to identify your browser, provide
                analytics, remember information about you such as your language
                preference or login information.
              </div>
            </div>
            <div className="d-flex cookies-sub-terms pb-3">
              <span className="Cookie-terms">●</span>
              <div className="Cookie-terms">
                Device: any internet connected device such as a phone, tablet,
                computer or any other device that can be used to visit
                capperauditor and use the services.
              </div>
            </div>
            <div className="d-flex cookies-sub-terms pb-3">
              <span className="Cookie-terms">●</span>
              <div className="Cookie-terms">
                Personal Data: any information that directly, indirectly, or in
                connection with other information — including a personal
                identification number — allows for the identification or
                identifiability of a natural person.
              </div>
            </div>
            <div className="d-flex cookies-sub-terms pb-3">
              <span className="Cookie-terms">●</span>
              <div className="Cookie-terms">
                Service: refers to the service provided by capperauditor as
                described in the relative terms (if available) and on this
                platform.
              </div>
            </div>
            <div className="d-flex cookies-sub-terms pb-3">
              <span className="Cookie-terms">●</span>
              <div className="Cookie-terms">
                Third-party service: refers to advertisers, contest sponsors,
                promotional and marketing partners, and others who provide our
                content or whose products or services we think may interest you.
              </div>
            </div>
            <div className="d-flex cookies-sub-terms pb-3">
              <span className="Cookie-terms">●</span>
              <div className="Cookie-terms">
                Website: site, which can be accessed via this URL:
                http://capperauditor.com
              </div>
            </div>

            <div className="cookies-policy-heading">Introduction</div>
            <div className="cookies-policy-description d-flex">
              This Cookie Policy explains how capperauditor and its affiliates
              (collectively "capperauditor", "we", "us", and "ours"), use
              cookies and similar technologies to recognize you when you visit
              our website, including without limitation http://capperauditor.com
              and any related URLs, mobile or localized versions and related
              domains / sub-domains ("Websites"). It explains what these
              technologies are and why we use them, as well as the choices for
              how to control them.
            </div>

            <div className="cookies-policy-heading">What is a cookie?</div>
            <div className="cookies-policy-description d-flex">
              A cookie is a small text file that is stored on your computer or
              other internet connected device in order to identify your browser,
              provide analytics, remember information about you such as your
              language preference or login information. They're completely safe
              and can't be used to run programs or deliver viruses to your
              device.
            </div>
            <div className="cookies-policy-heading">Why do we use cookies?</div>
            <div className="cookies-policy-description d-flex">
              We use first party and/or third party cookies on our website for
              various purposes such as:
            </div>
            <div className="cookies-policy-description d-flex">
              <span>●</span>To facilitate the operation and functionality of our
              website;
            </div>
            <div className="cookies-policy-description d-flex">
              <span>●</span>To improve your experience of our website and make
              navigating around them quicker and easier;
            </div>
            <div className="cookies-policy-description d-flex">
              <span>●</span>To allow us to make a bespoke user experience for
              you and for us to understand what is useful or of interest to you;
            </div>
            <div className="cookies-policy-description d-flex">
              <span>●</span>To analyze how our website is used and how best we
              can customize it;
            </div>
            <div className="cookies-policy-description d-flex">
              <span>●</span>To identify future prospects and personalize
              marketing and sales interactions with it;
            </div>
            <div className="cookies-policy-description d-flex">
              <span>●</span>To facilitate the tailoring of online advertising to
              your interests.
            </div>
            <div className="cookies-policy-description d-flex">
              <span>●</span>You: a person or entity that is registered with
              capperauditor to use the Services.
            </div>

            <div className="cookies-policy-heading">
              What type of cookies does capperauditor use?
            </div>

            <div className="cookies-policy-description d-flex">
              Cookies can either be session cookies or persistent cookies. A
              session cookie expires automatically when you close your browser.
              A persistent cookie will remain until it expires or you delete
              your cookies. Expiration dates are set in the cookies themselves;
              some may expire after a few minutes while others may expire after
              multiple years. Cookies placed by the website you’re visiting are
              called “first party cookies”.
            </div>
            <div className="cookies-policy-description d-flex">
              Strictly Necessary cookies are necessary for our website to
              function and cannot be switched off in our systems. They are
              essential in order to enable you to navigate around the website
              and use its features. If you remove or disable these cookies, we
              cannot guarantee that you will be able to use our website.
            </div>
            <div className="cookies-policy-description d-flex">
              We use the following types of cookies in our website:
            </div>

            <div className="cookies-policy-heading">Essential Cookies</div>
            <div className="cookies-policy-description d-flex">
              We use essential cookies to make our website work. These cookies
              are strictly necessary to enable core functionality such as
              security, network management, your cookie preferences and
              accessibility. Without them you wouldn't be able to use basic
              services. You may disable these by changing your browser settings,
              but this may affect how the Websites function.
            </div>

            <div className="cookies-policy-heading">
              Performance and Functionality Cookies
            </div>
            <div className="cookies-policy-description d-flex">
              These cookies are used to enhance the performance and
              functionality of our website but are non-essential to their use.
              However, without these cookies, certain functionality like videos
              may become unavailable or you would be required to enter your
              login details every time you visit the website as we would not be
              able to remember that you had logged in previously.
            </div>

            <div className="cookies-policy-heading">Marketing Cookies</div>
            <div className="cookies-policy-description d-flex">
              These account-based marketing cookies enable us to identify future
              prospects and personalize sales and marketing interactions with
              them.
            </div>

            <div className="cookies-policy-heading">
              Analytics and Customization Cookies
            </div>

            <div className="cookies-policy-description d-flex">
              These cookies collect information that is used to help us
              understand how our website is being used or how effective our
              marketing campaigns are, or to help us customize our website for
              you.
            </div>
            <div className="cookies-policy-description d-flex">
              We use cookies served by Google Analytics to collect limited data
              directly from end-user browsers to enable us to better understand
              your use of our website. Further information on how Google
              collects and uses this data can be found at:
              https://www.google.com/policies/privacy/partners/. You can opt-out
              of all Google supported analytics on our Websites by visiting:
              https://tools.google.com/dlpage/gaoptout.
            </div>

            <div className="cookies-policy-heading">Social Media Cookies</div>
            <div className="cookies-policy-description d-flex">
              These cookies are used when you share information using a social
              media sharing button or “like” button on our Site or you link your
              account or engage with our content on or through a social
              networking website such as Facebook, Twitter or Google+. The
              social network will record that you have done this. These cookies
              may also include certain code that has been placed on the platform
              to help track conversions from ads, optimize ads based on
              collected data, build targeted audiences for future ads, and
              remarket to qualified users who have already taken certain action
              on the platform.
            </div>

            <div className="cookies-policy-heading">Third Party Cookies</div>
            <div className="cookies-policy-description d-flex">
              Some cookies that have been set on our website are not set on a
              first party basis by capperauditor. The Websites can be embedded
              with content from third parties to serve advertising. These third
              party service providers may set their own cookies on your web
              browser. Third party service providers control many of the
              performance and functionality, advertising, marketing and
              analytics cookies described above. We do not control the use of
              these third party cookies as cookies can only be accessed by the
              third party that originally set them.
            </div>

            <div className="cookies-policy-heading">
              How you can manage cookies?
            </div>
            <div className="cookies-policy-description d-flex">
              Most browsers allow you to control cookies through their
              'settings' preferences. However, if you limit the ability of
              websites to set cookies, you may worsen your overall user
              experience, since it will no longer be personalized to you. It may
              also stop you from saving customized settings like login
              information. Browser manufacturers provide help pages relating to
              cookie management in their products.
            </div>
            <div className="cookies-policy-description d-flex">
              Browser manufacturers provide help pages relating to cookie
              management in their products. Please see below for more
              information.
            </div>
            <div className="cookies-policy-description d-flex">
              <span>●</span>Google Chrome
            </div>
            <div className="cookies-policy-description d-flex">
              <span>●</span>Internet Explorer
            </div>
            <div className="cookies-policy-description d-flex">
              <span>●</span>Mozilla Firefox
            </div>
            <div className="cookies-policy-description d-flex">
              <span>●</span>Safari (Desktop)
            </div>
            <div className="cookies-policy-description d-flex">
              <span>●</span>Safari (Mobile)
            </div>
            <div className="cookies-policy-description d-flex">
              <span>●</span>Safari (Mobile)
            </div>
            <div className="cookies-policy-description d-flex">
              <span>●</span>Android Browser
            </div>
            <div className="cookies-policy-description d-flex">
              <span>●</span>Opera
            </div>
            <div className="cookies-policy-description d-flex">
              <span>●</span>Opera Mobile
            </div>

            <div className="cookies-policy-heading">
              Blocking and disabling cookies and similar technologies
            </div>
            <div className="cookies-policy-description d-flex">
              Wherever you're located you may also set your browser to block
              cookies and similar technologies, but this action may block our
              essential cookies and prevent our website from functioning
              properly, and you may not be able to fully utilize all of its
              features and services. You should also be aware that you may also
              lose some saved information (e.g. saved login details, site
              preferences) if you block cookies on your browser. Different
              browsers make different controls available to you. Disabling a
              cookie or category of cookie does not delete the cookie from your
              browser, you will need to do this yourself from within your
              browser, you should visit your browser's help menu for more
              information.
            </div>

            <div className="cookies-policy-heading">
              Changes To Our Cookie Policy
            </div>
            <div className="cookies-policy-description d-flex">
              We may change our Service and policies, and we may need to make
              changes to this Cookie Policy so that they accurately reflect our
              Service and policies. Unless otherwise required by law, we will
              notify you (for example, through our Service) before we make
              changes to this Cookie Policy and give you an opportunity to
              review them before they go into effect. Then, if you continue to
              use the Service, you will be bound by the updated Cookie Policy.
              If you do not want to agree to this or any updated Cookie Policy,
              you can delete your account.
            </div>

            <div className="cookies-policy-heading">Your Consent</div>
            <div className="cookies-policy-description d-flex">
              By using our website, registering an account, or making a
              purchase, you hereby consent to our Cookie Policy and agree to its
              terms.
            </div>

            <div className="cookies-policy-heading">Contact Us</div>
            <div className="cookies-policy-description d-flex">
              Don't hesitate to contact us if you have any questions regarding
              our Cookie Policy.
            </div>
            <div className="cookies-policy-description d-flex">
              Via Email: support@capperauditor.com
            </div>
          </div>
        </div>
      </section>
      <HomePageFooter />
    </>
  );
};
export default CookiePolicy;
