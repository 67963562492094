import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import NavigationBar from "./Navbar";
import "../css/profile.css";
import { Button, Card, Modal, Spinner } from "react-bootstrap";
import Avatar from "./Avatar";
import apiService from "../services/apiService";
import { eventBus } from "../services/events";
import { CONSTANT } from "../common/constant";
import { useTranslation } from "react-i18next";
import { dynamicActivate } from "../i18n";
import ShareModel from "./molecule/shareModel/ShareModel";
import Model from "./molecule/model/Model";
import SearchBar from "./molecule/searchBar/SearchBar";
import InputCheckbox from "./molecule/inputCheckbox/InputCheckbox";
import InputButton from "./molecule/InputButton";
import Accordion from "react-bootstrap/Accordion";
import { leagues } from "../json/allData";
import AddPrediction from "./molecule/Prediction/AddPrediction";
import PredictionList from "./molecule/prediction-list/PredictionList";
import ChartSummary from "./molecule/Chart/ChartSummary";
import { Helmet } from "react-helmet";
import { Environment } from "../environment";
import MyLoader from "./Custom loader/CustomLoaderuserProfile";
import PredictionListCustomLoader from "./Custom loader/PredictionListCustomLoader";
import SportLeagueModelLoader from "./Custom loader/SportLeagueModelLoader";
import MobilePredictionListLoader from "./Custom loader/MobilePredictionListLoader";
import HomePageFooter from "./molecule/homepage/HomePageFooter/HomePageFooter";
import { prepareFullName } from "../helpers/formater";
import FollowingModal from "./General/FollowingModal";
import UserRewardsSection from "./Rewards/UserRewardsSection";
import PredictionsListLoader from "./CustomSkeletonLoaders/PredictionsListLoader";
import { useMediaQuery } from "react-responsive";

const _ = require("lodash");
// dynamicActivate(localStorage.getItem("language"));

const UserProfile = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const isMobile = useMediaQuery({ maxWidth: 768 });
  const isTablet = useMediaQuery({ maxWidth: 1024 });

  const pageSize = 10;

  const loginUserId = localStorage.getItem("userId");
  localStorage.setItem("isInterVal", false);

  const [activeTab, setActiveTab] = useState("predictions");

  const [currPage, setCurrPage] = useState(0);
  const [pageState, setPageState] = useState(0);
  const [isEditSport, setIsEditSport] = useState(false);
  const [showLoader, setShowLoader] = useState(true);
  const [showPredictionLoader, setShowPredictionLoader] = useState(true);
  const reload = () => window.location.reload();

  const [modelShow, setModelshow] = useState(false);
  const [showFollowerModel, setShowFollowerModel] = useState(false);
  const [showFollowingModel, setShowFollowingModel] = useState(false);
  const [showShareProfileModel, setShowShareProfileModel] = useState(false);
  const [charCount, setCharCount] = useState(0);
  const [userProfile, setUSerProfile] = useState({});
  const [userName, setUserName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [doRef, setDoRef] = useState(false);
  const [countData, setCountData] = useState(0);
  const [showDeleteBtn, setShowDeleteBtn] = useState(
    "btn-img-delete validation-error-hide"
  );
  const [editProfile, setEditProfile] = useState({});
  const string = firstName?.charAt(0);
  const [error, setError] = useState("img-size-error validation-error-hide");
  const [introLine, setIntroLine] = useState("img-size-intro show-intro-line");
  const [conformation, setconformation] = useState(
    "conformation-part hide-conformation-part"
  );
  const increamentUserCount = () => {
    setCountData(countData + 1);
  };
  const [follow, setFollow] = useState([]);
  const [following, setFollowing] = useState([]);
  const [hideDeleteButton, setHideDeleteButton] = useState(false);
  const [typeError, setTypeError] = useState(
    "img-size-error validation-error-hide"
  );
  // const [staticLeagues, setStaticLeagues] = useState([]);
  const [popularLeagues, setPopularLeagues] = useState([]);
  const [popularLeaguesHideShow, setPopularLeaguesHideShow] = useState(
    "popular-leagues-container-show"
  );
  const [predictionModel, setPredictionModle] = useState(false);
  const [addPredictionsModel, setAddPredictionModle] = useState(false);
  const [modelLabel, setModelLabel] = useState(t("profile.selectSport"));
  const [sportContains, setSportContains] = useState(
    "sport-container sport-container-show"
  );
  const [leagueesContains, setLeagueesContains] = useState(
    "leaues-country-container leagues-country-container-hide"
  );
  const [selectedButton, setSelectedButton] = useState(true);
  const [addSomePredictionsModel, setSomeAddPredictionModel] = useState(
    "add-prediction-model-hide"
  );
  const [sportData, setSportData] = useState([]);
  const [countryLeaguesData, setCountryLeaguesData] = useState([]);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  const [isCountryCheck, setIsCountryCheck] = useState([]);
  const [emptyMsgShow, setEmptyMsgShow] = useState(
    "empty-error-msg empty-error-msg-hide"
  );
  const [leagueEmptyMsgShow, setLeagueEmptyMsgShow] = useState(
    "empty-error-msg empty-error-msg-hide"
  );
  const [sportId, setSportId] = useState("");
  const [sportName, setSportName] = useState("");
  const [sportName_ua, setsportName_ua] = useState("");
  const [allLeaguesData, setAllLeaguesData] = useState([]);
  const [betsData, setBetsData] = useState([]);
  const [betsDataCount, setBetsDataCount] = useState("");
  const [predictionData, setPredictionData] = useState(true);
  const [predictionTitleColor, setPredictionTitleColor] =
    useState("predictions-active");
  const [analyticsTitleColor, setAnalyticsTitleColor] = useState("analytics");
  const [analyticsData, setAnalyticsData] = useState(false);
  const [showMessage, setShowMessage] = useState("");
  const [showSportLoader, setShowSportLoader] = useState(true);
  const [showLeaugeLoader, setShowLeaugeLoader] = useState(true);

  const arr = [...isCheck, ...isCountryCheck];

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/signin");
    }
  }, []);

  const checkForLoggedInUSer = async () => {
    const profileData = await apiService.getUserProfileCheckAndSet();

    if (profileData.isVerifyEmail === false) {
      navigate("/signin");
    }
  };

  useEffect(() => {
    checkForLoggedInUSer();
  }, []);

  const getSportData = async () => {
    eventBus.emit(CONSTANT.SHOW_LOADER);
    let res = await apiService.getAllSports({});
    eventBus.emit(CONSTANT.HIDE_LOADER);
    if (localStorage.getItem("prediction-list")) {
      const data = JSON.parse(localStorage.getItem("prediction-list"));
      const localStorageSportId = data?.map((item) => item.sportId);
      res = res?.filter((sp) => {
        if (!localStorageSportId.includes(sp.id)) {
          return sp;
        }
      });
    }
    setSportData(res);
    if (res) {
      setShowSportLoader(false);
    }
    increamentUserCount();
  };

  useEffect(() => {
    getSportData();
  }, [sportId]);

  const userprofile = async () => {
    setDoRef(false);
    eventBus.emit(CONSTANT.SHOW_LOADER);
    let res;
    if (localStorage.getItem("profile") !== "null") {
      res = JSON.parse(localStorage.getItem("profile"));
    } else {
      res = await apiService.getUserProfile();
    }
    eventBus.emit(CONSTANT.HIDE_LOADER);
    if (res) {
      setShowLoader(false);
    }
    setUSerProfile(res);
    setFollowing(res?.following);
    const newFollower = [];
    res?.followers.map((fol) => {
      fol.isFollowed = false;
      res?.following.map((fl) => {
        if (fol._id.toString() === fl._id) {
          fol.isFollowed = true;
        }
      });
      newFollower.push(fol);
    });
    setFollow(newFollower);
    setUserName(res?.userName);
    setFirstName(res?.firstName);
    setCharCount(160 - res?.bio.length);
    setconformation("conformation-part hide-conformation-part");
    setTypeError("img-size-error validation-error-hide");
    setError("img-size-error validation-error-hide");
    if (!userProfile.profileImgUrl || userProfile.profileImgUrl == "") {
      setIntroLine("img-size-intro show-intro-line");
    } else {
      setShowDeleteBtn("btn-img-delete validation-error-show");
      setIntroLine("img-size-intro hide-intro-line");
    }
  };

  const handleEditProfileDeletebutton = () => {
    if (!editProfile.profileImgUrl || editProfile.profileImgUrl == "") {
      setIntroLine("img-size-intro show-intro-line");
    } else {
      setShowDeleteBtn("btn-img-delete validation-error-show");
      setIntroLine("img-size-intro hide-intro-line");
    }
    setCountData(countData + 1);
  };

  const fetchData = async () => {
    await userprofile();
  };

  useEffect(() => {
    document.body.classList.add("userprofile-containt");
    // i18n.changeLanguage("ua")
    fetchData();
  }, [modelShow, doRef]);

  const btnDeletePicture = () => {
    setconformation("conformation-part show-conformation-part");
    setShowDeleteBtn("btn-img-delete hide-conformation-part");
  };
  // ----- editProfileModel funtion ----
  const handleClose = () => {
    setModelshow(false);
  };
  const handleShow = () => {
    setModelshow(true);
  };
  const handlefollow = async (id) => {
    const res = await apiService.followUser(id);
    if (res) {
      // reload();
      setDoRef(true);
    }
  };
  const hadleSelectSportsBtn = () => {
    setModelLabel(t("profile.selectSport"));
    setIsCheck([]);
    setSportContains("sport-container sport-container-show");
    setLeagueesContains(
      "leaues-country-container leagues-country-container-hide"
    );
  };
  const handlesports = async (
    sport_id,
    sportName,
    sportName_ua,
    isEdit = false
  ) => {
    setSportName(sportName);
    setSportId(sport_id);
    setsportName_ua(sportName_ua);
    if (!isEdit) {
      setModelLabel(t("profile.selectLeague"));
    } else {
      setModelLabel(t("profile.EditLeagues"));
    }
    setSportContains("sport-container sport-container-hide");
    if (
      leagueesContains ==
      "leaues-country-container leagues-country-container-show"
    ) {
      handleSelectedSportLeagueData();
      setLeagueEmptyMsgShow("empty-error-msg empty-error-msg-hide");
      increamentUserCount();
    }
    setLeagueesContains(
      "leaues-country-container leagues-country-container-show"
    );
    setSportContains("sport-container sport-container-hide");
  };
  const handleSelectedSportLeagueData = async () => {
    const arr = [];
    eventBus.emit(CONSTANT.SHOW_LOADER);
    if (sportId !== "") {
      const res = await apiService.getLeagues({
        id: sportId,
        language: apiService.getProfileFromStorage().Language,
      });
      if (res) {
        setShowLeaugeLoader(false);
      }
      if (res?.popularLeagues) {
        setPopularLeagues(res.popularLeagues);
      }
      eventBus.emit(CONSTANT.HIDE_LOADER);
      for (const data in res?.leaguseByCountries) {
        arr.push(res?.leaguseByCountries[data]);
        setAllLeaguesData((oldState) => [
          ...oldState,
          ...res.leaguseByCountries[data].leagues,
        ]);
      }
      setCountryLeaguesData(arr);
    }
  };
  useEffect(() => {
    handleSelectedSportLeagueData();
  }, [sportId]);
  const handleSportSearch = async (e) => {
    let res = await apiService.getAllSports({
      search: e.target.value,
    });
    if (localStorage.getItem("prediction-list")) {
      let items = JSON.parse(localStorage.getItem("prediction-list"));
      let itemsIds = items.map((i) => i.sportId);
      res = res.filter((i) => {
        if (!itemsIds.includes(i.id)) return i;
      });
    }
    setSportData(res);
    if (res.length < 1 && e.target.value != "") {
      setEmptyMsgShow("empty-error-msg empty-error-msg-show");
    } else {
      setEmptyMsgShow("empty-error-msg empty-error-msg-hide");
    }
  };
  const handleLeagueSearch = async (e) => {
    const arr = [];
    const res = await apiService.getLeagues({
      id: sportId,
      search: e.target.value,
      language: apiService.getProfileFromStorage().Language,
    });

    for (const data in res.leaguseByCountries) {
      arr.push(res.leaguseByCountries[data]);
      setAllLeaguesData((oldState) => [
        ...oldState,
        ...res.leaguseByCountries[data].leagues,
      ]);
    }
    if (e.target.value == "") {
      setPopularLeagues(res.popularLeagues);
    } else {
      setPopularLeagues([]);
    }

    setCountryLeaguesData(arr);
    if (arr.length < 1 && e.target.value != "") {
      setLeagueEmptyMsgShow("empty-error-msg empty-error-msg-show");
      setPopularLeaguesHideShow("popular-leagues-container-hide");
    } else {
      setLeagueEmptyMsgShow("empty-error-msg empty-error-msg-hide");
      setPopularLeaguesHideShow("popular-leagues-container-show");
    }
  };
  const handleAllCheckBox = () => {
    setIsCheckAll(!isCheckAll);
    setIsCheck(popularLeagues.map((item) => item.id.toString()));
    popularLeagues.map((item) => {
      allLeaguesData.push(item);
    });
    setIsCountryCheck(
      allLeaguesData.map((item) => {
        return item.id.toString();
      })
    );
    if (isCheckAll) {
      setIsCheck([]);
      setIsCountryCheck([]);
    }
  };
  const handleCheckBox = (e) => {
    const { id, checked } = e.target;
    setIsCheck(() => [...isCheck, id]);
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== id));
    }
  };
  const handleCountryCheckbox = (e) => {
    const { id, checked } = e.target;
    setIsCountryCheck([...isCountryCheck, id]);
    if (!checked) {
      setIsCountryCheck(isCountryCheck.filter((item) => item !== id));
    }
  };
  // add-prediction --------
  const handleAddPredictionHide = () => {
    setAddPredictionModle(false);
  };

  const handleAddPrediction = async () => {
    let leageData = _.uniq([...isCheck, ...isCountryCheck]);
    const predicationData = {
      sportId: sportId,
      sportName: sportName,
      leagues: leageData,
      activeKey: true,
      sportName_ua: sportName_ua,
    };
    if (localStorage.getItem("prediction-list")) {
      var items = [];
      items = JSON.parse(localStorage.getItem("prediction-list"));
      let isExist = false;
      items = items.map((i) => {
        if (i.sportId !== sportId) {
          i.activeKey = false;
        } else {
          i.leagues = predicationData.leagues;
          i.activeKey = true;
          isExist = true;
        }
        return i;
      });
      if (!isExist) {
        items.push(predicationData);
        localStorage.setItem("prediction-list", JSON.stringify(items));
      } else {
        localStorage.setItem("prediction-list", JSON.stringify(items));
      }
    } else {
      localStorage.setItem(
        "prediction-list",
        JSON.stringify([predicationData])
      );
    }
    await apiService.createFavSport({
      list: JSON.parse(localStorage.getItem("prediction-list")),
    });
    setSomeAddPredictionModel("add-prediction-model-show");
    setAddPredictionModle(true);
    setPredictionModle(false);
  };
  // ------- predication model function  -------
  const handlePredictionShow = async () => {
    if (localStorage.getItem("language") === "en") {
      navigate("/en/sportline");
    } else {
      navigate("/sportline");
    }
  };

  const handleSportShowModel = async () => {
    setPredictionModle(true);
    setAddPredictionModle(false);
    if (sportContains === "sport-container sport-container-show") {
      getSportData();
      setEmptyMsgShow("empty-error-msg empty-error-msg-hide");
    }
    setSportContains("sport-container sport-container-show");
    setLeagueesContains(
      "leaues-country-container leagues-country-container-hide"
    );
    setModelLabel(t("profile.selectSport"));
    setSportId("");
    setIsCheck([]);
    setIsCountryCheck([]);
    increamentUserCount();
  };
  const handlePredictionHide = () => {
    setPredictionModle(false);
  };
  const handleLeagueDataShow = async (item, data) => {
    setIsEditSport(data);
    setPredictionModle(true);
    setAddPredictionModle(false);
    setSportContains("sport-container sport-container-hide");
    setLeagueesContains(
      "leaues-country-container leagues-country-container-show"
    );
    setSelectedButton(false);
    setSportId(item.sportId);
    setIsCheck(item.leagues);
    // setIsCountryCheck([]);
    setIsCountryCheck(item.leagues);
    setModelLabel(t("profile.EditLeagues"));
    const res = await apiService.getLeagues({
      id: item.sportId,
      language: apiService.getProfileFromStorage().Language,
    });
    if (res) {
      setShowLeaugeLoader(false);
    }
    if (res?.popularLeagues) {
      setPopularLeagues(res.popularLeagues);
    }
    eventBus.emit(CONSTANT.HIDE_LOADER);
    for (const data in res?.leaguseByCountries) {
      arr.push(res?.leaguseByCountries[data]);
      setAllLeaguesData((oldState) => [
        ...oldState,
        ...res.leaguseByCountries[data].leagues,
      ]);
    }
    setCountryLeaguesData(arr);
    // handlesports(item.sportId, item.sportName, item.sportName_ua, true);
    // setIsCheck(item.leagues)
  };
  // ----- follower Model funtion ----

  const handlefollowerShow = () => {
    setShowFollowerModel(true);
  };
  const handlefollowerHide = () => {
    setShowFollowerModel(false);
  };

  // ------ following model function -----

  const handlefollowingShow = () => {
    setShowFollowingModel(true);
  };
  const handlefollowingHide = () => {
    setShowFollowingModel(false);
  };

  // -------- Share profile function ------

  const handleShareProfileShow = () => {
    setShowShareProfileModel(true);
  };
  const handleShareProfileHide = () => {
    setShowShareProfileModel(false);
  };
  const handleUnFollow = async (id) => {
    const res = await apiService.unfollowUser(id);
    if (res) {
      setDoRef(true);
    }
  };

  const profilePicVaildation = async (file) => {
    // Validation of Image
    const MAX_FILE_SIZE = 4096;
    const fileSizeKiloBytes = file?.size / 1024;
    if (fileSizeKiloBytes > MAX_FILE_SIZE) {
      setError("img-size-error validation-error-show");
      setShowDeleteBtn("btn-img-delete validation-error-hide");
      setIntroLine("img-size-intro show-intro-line");
    } else {
      setError("img-size-error validation-error-hide");
      setIntroLine("img-size-intro hide-intro-line");
      setShowDeleteBtn("btn-img-delete validation-error-show");
    }
    if (
      file.type != "image/jpg" &&
      file.type != "image/png" &&
      file.type != "image/jpeg"
    ) {
      setTypeError("img-size-error validation-error-show");
      setIntroLine("img-size-intro show-intro-line");
      setShowDeleteBtn("btn-img-delete validation-error-hide");
    } else {
      setTypeError("img-size-error validation-error-hide");
      setShowDeleteBtn("btn-img-delete validation-error-show");
      setIntroLine("img-size-intro hide-intro-line");
    }
  };

  const handlleBioCount = (e) => {
    setEditProfile({ ...editProfile, bio: e.target.value });
  };
  const updateProfile = async (url) => {
    eventBus.emit("PROFILE_PIC_UPDATE", null);
    setEditProfile((oldState) => {
      return {
        ...oldState,
        profileImgUrl: url,
      };
    });
  };
  const deleteProfilePic = async () => {
    setEditProfile((oldState) => {
      return {
        ...oldState,
        profileImgUrl: "",
      };
    });
    setUSerProfile((oldState) => {
      return {
        ...oldState,
        profileImgUrl: "",
      };
    });

    eventBus.emit("DELETE_PROFILE_PIC", null);
    setHideDeleteButton(true);
  };
  const deleteProfilePicture = () => {
    setIntroLine("img-size-intro show-intro-line");
    setconformation("conformation-part hide-conformation-part");
    deleteProfilePic();
  };
  const noDeleteProfilePicture = () => {
    setconformation("conformation-part hide-conformation-part");
    setShowDeleteBtn("btn-img-delete validation-error-show");
  };

  const handleUpdateProfile = async () => {
    eventBus.emit(CONSTANT.SHOW_LOADER);
    const res = await apiService.updateUserProfile(editProfile);
    eventBus.emit(CONSTANT.HIDE_LOADER);
    setUSerProfile(res);
    if (res) {
      setModelshow(false);
      reload();
    }
  };

  const handleUserProfile = async (item, pageNumber = currPage || 0) => {
    const res = await apiService.getPredictionOrderByUserId(
      item._id,
      pageSize,
      pageNumber
    );
    if (item._id.toString() == loginUserId) {
      navigate(`/userprofile`);
    } else {
      navigate(`/profile/${item._id}`, {
        state: res,
        length: res?.length,
      });
    }
  };
  const manageData = async (pageNumber = currPage || 0) => {
    const res = await apiService.getPredictionOrderList(pageSize, pageNumber);
    if (pageNumber == 0) {
      setBetsData(res?.getPredictionOrderList);
    } else {
      setBetsData((oldState) => [...oldState, ...res.getPredictionOrderList]);
    }
    setPageState(Math.ceil(res?.totalPredictionOrder / pageSize));
    if (res) {
      setShowPredictionLoader(false);
    }
    setBetsDataCount(res?.totalPredictionOrder);
    if (pageState == currPage + 1) {
      setShowMessage(t("feed.showMessage"));
    }
  };
  // const ShowMorePredictionsData = async () => {
  //   let diff = betsDataCount - (initialIndex + 10)
  //   const res = await apiService.getPredictionOrderList();
  //   if (initialIndex < betsDataCount) {
  //     setBetsData((oldState) => [...oldState, ...res?.slice(initialIndex, diff < 10 ? initialIndex + diff : initialIndex + 10)]);
  //   }
  //   if (betsDataCount - (initialIndex + 10) > 10) {
  //     setInitialIndex(initialIndex + 10)
  //   } else {
  //     setInitialIndex(initialIndex + diff)
  //   }
  //   // setBetsData(res);
  //   if (diff < 10) {
  //     setShowMessage(t("feed.showMessage"))
  //   }
  // };

  const ShowMorePredictionsData = async () => {
    setCurrPage(currPage + 1);
  };
  useEffect(() => {
    manageData();
  }, [countData, currPage]);

  // prediction-tab
  const handlePredictionTabData = () => {
    setPredictionData(true);
    setPredictionTitleColor("predictions-active");
    setAnalyticsTitleColor("analytics");
    if (analyticsData) {
      setAnalyticsData(false);
    }
  };
  const handleAnalyticsData = () => {
    setAnalyticsTitleColor("analytics-active");
    setPredictionTitleColor("predictions");
    setAnalyticsData(true);
    if (predictionData) {
      setPredictionData(false);
    }
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {prepareFullName(userProfile) +
            " (@" +
            userProfile?.userName +
            ")" +
            " | Predictions: " +
            betsDataCount || 0 + "| Capperauditor"}
        </title>
        <description>{userProfile?.bio || ""}</description>
        <meta
          property="og:url"
          content={
            process.env.REACT_APP_shareLinkUrl +
            "profile/" +
            userProfile?.userName
          }
        />
        <meta property="og:description" content={userProfile?.bio || ""} />
        <meta
          property="og:title"
          content={
            userProfile?.firstName?.charAt(0).toUpperCase() +
              userProfile?.firstName?.slice(1) +
              " " +
              userProfile?.lastName?.charAt(0).toUpperCase() +
              userProfile?.lastName?.slice(1) +
              "(@" +
              userProfile?.userName +
              ")" +
              " | Predictions: " +
              betsDataCount || 0 + "| Capperauditor"
          }
        />
        <meta
          property="og:image"
          content={
            userProfile?.profileImgUrl ||
            process.env.REACT_APP_shareLinkUrl + "navbar-logo.svg"
          }
        />
        <link
          rel="alternate"
          href="https://capperauditor.com/userprofile"
          hreflang="uk"
        />{" "}
        <link
          rel="alternate"
          href="https://capperauditor.com/en/userprofile"
          hreflang="en"
        />{" "}
        <link
          rel="alternate"
          href="https://capperauditor.com/userprofile"
          hreflang="x-default"
        />
      </Helmet>
      <NavigationBar countData={countData} />
      <div className="profile">
        <section className="profile-section">
          <div className="profile-container container-1240">
            {/* user Profile top loader start */}
            {showLoader ? (
              <MyLoader />
            ) : (
              <div className="">
                <div className="profile-top-part mt-0">
                  <div
                    className="profile-avtar-circle rounded-circle rounded-profile-circle d-flex align-items-center justify-content-center"
                    alt="Avatar"
                  >
                    <div>
                      {userProfile.profileImgUrl !== "" ? (
                        <img
                          src={userProfile.profileImgUrl}
                          value={userProfile.profileImgUrl}
                          alt="avatar"
                          className="uploaded-img"
                          style={{ display: "block" }}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                    {userProfile.profileImgUrl === "" ? (
                      <p className="useName-profile-letter d-flex align-items-center justify-content-center">
                        {string}
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="userprofile-details d-flex justify-content-between">
                    <div className="userProfile-share ">
                      <div className="user-fullname">
                        {prepareFullName(userProfile)}
                      </div>
                      <div className="user-username">
                        <p className="mb-3">@{userProfile?.userName}</p>
                      </div>
                      <div>
                        <button
                          className="btn-edit-profile"
                          onClick={handleShow}
                        >
                          {t("profile.editProfile")}
                        </button>
                      </div>
                    </div>
                    <div
                      className="share-button"
                      onClick={handleShareProfileShow}
                    >
                      <svg
                        width="24"
                        height="21"
                        viewBox="0 0 24 22"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M16.6667 14.6301C16.2985 14.6301 16 14.9286 16 15.2968V18.5926C16 19.3676 15.4042 19.9635 14.6667 19.9635H2.66667C1.93042 19.9635 1.33333 19.3664 1.33333 18.6301V6.63014C1.33333 5.89389 1.93042 5.29681 2.66667 5.29681H7.33333C7.7 5.29681 8 4.99681 8 4.63014C8 4.26347 7.7 3.96347 7.33333 3.96347H2.66667C1.19375 3.96347 0 5.15931 0 6.63014V18.5926C0 20.0651 1.19375 21.2593 2.66667 21.2593L14.6667 21.2968C16.1396 21.2968 17.3333 20.1026 17.3333 18.6301V15.2968C17.3333 14.9301 17.0333 14.6301 16.6667 14.6301ZM23.7625 5.45097L17.0958 0.155141C16.8146 -0.0805258 16.3942 -0.0440674 16.1571 0.239766C15.9214 0.522308 15.9592 0.943099 16.2417 1.17852L21.4917 5.29681H15.3333C11.2542 5.29681 8 8.55097 8 12.6301V13.9635C8 14.3301 8.3 14.6301 8.66667 14.6301C9.03333 14.6301 9.33333 14.3301 9.33333 13.9635V12.6301C9.33333 9.32181 12.025 6.63014 15.3333 6.63014H21.4917L16.2375 10.7851C15.955 11.0208 15.9172 11.4414 16.1529 11.7239C16.2875 11.8801 16.475 11.9635 16.6667 11.9635C16.8177 11.9635 16.9688 11.9127 17.0938 11.8085L23.7604 6.47518C23.9125 6.35097 24 6.15931 24 5.92597C24 5.69264 23.9125 5.58014 23.7625 5.45097Z"
                          fill="#7F87B3"
                          className="share-path"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                <div className="row profile-center-part">
                  <div
                    className="col followers-detail"
                    onClick={handlefollowerShow}
                  >
                    <div className="followers-count">{follow.length}</div>
                    <div className="followers">{t("profile.followers")}</div>
                  </div>
                  <div
                    className="col followering-detail"
                    onClick={handlefollowingShow}
                  >
                    <div className="followers-count">{following.length}</div>
                    <div className="followers">{t("profile.following")}</div>
                  </div>
                  <div className="col prediction-detail">
                    <div className="followers-count">{betsDataCount}</div>
                    <div className="followers">{t("profile.predictions")}</div>
                  </div>
                </div>
                <div className="profile-end-part">
                  {userProfile.bio == "" ? (
                    <Link to="#" className="add-bio" onClick={handleShow}>
                      {t("profile.addYourBio")}
                    </Link>
                  ) : (
                    <div className="show-bio-data">{userProfile.bio}</div>
                  )}
                </div>
              </div>
            )}

            {/* user Profile top loader end */}
          </div>
        </section>
        <section className="profile-bottom-section container-1240">
          <div className="profile-content ">
            <div className="user-profile-left-panel prediction-div">
              <div className="user-profile-tabs">
                {/* prediction-tab */}
                <div
                  className={
                    activeTab === "predictions"
                      ? "user-profile-tab active"
                      : "user-profile-tab"
                  }
                  onClick={() => setActiveTab("predictions")}
                >
                  <div className="tab-symbol">
                    <img src="/profileImg/list.svg" alt="" />
                  </div>
                  <div>{t("profile.predictions")}</div>
                </div>
                <div
                  className={
                    activeTab === "analytics"
                      ? "user-profile-tab active"
                      : "user-profile-tab"
                  }
                  onClick={() => setActiveTab("analytics")}
                >
                  <div className="tab-symbol">
                    <img src="/profileImg/stat.svg" alt="" />
                  </div>
                  <div>{t("profile.analytics")}</div>
                </div>
                {isTablet && (
                  <div
                    className={
                      activeTab === "reward"
                        ? "user-profile-tab active"
                        : "user-profile-tab"
                    }
                    onClick={() => setActiveTab("reward")}
                  >
                    <div className="tab-symbol">
                      <img src="/profileImg/Achievements.svg" alt="" />
                    </div>
                    <div>{t("rewards.reward")}</div>
                  </div>
                )}
              </div>

              <div style={{ display: predictionData ? "block" : "none" }}>
                <div
                  className="userProfile-add-prediction-button"
                  style={{
                    display: activeTab === "predictions" ? "block" : "none",
                  }}
                >
                  <button
                    className="btn-add-prediction prediction-list-top-btn"
                    onClick={handlePredictionShow}
                  >
                    {t("profile.addPredictions")}
                  </button>
                </div>
              </div>
            </div>
            {/* Predictions-tab-data */}
            {showPredictionLoader && isMobile ? (
              <div className="">
                <div className="mobile-predictionlistLoader">
                  <MobilePredictionListLoader />
                </div>
              </div>
            ) : (
              <div
                className="main-content-section"
                style={{
                  display: activeTab === "predictions" ? "flex" : "none",
                }}
              >
                <div className="prediction-div">
                  {showPredictionLoader && <PredictionsListLoader />}
                  <div
                    className="pb-3"
                    style={{
                      display: betsDataCount > 0 ? "block" : "none",
                    }}
                  >
                    <PredictionList
                      betsData={betsData}
                      countData={countData}
                      currPage={currPage}
                      pageSize={pageSize}
                      increamentUserCount={increamentUserCount}
                      onClick={ShowMorePredictionsData}
                      showMessage={showMessage}
                      totalBetsData={betsDataCount}
                    />
                  </div>
                  <div
                    className="add-predictions"
                    style={{
                      display: betsDataCount > 0 ? "none" : "block",
                      marginBottom: "100px",
                    }}
                  >
                    <div className="d-flex justify-content-center">
                      <div
                        className="predictions-avtar-circle rounded-circle rounded-profile-circle d-flex align-items-center justify-content-center"
                        alt="Avatar"
                      >
                        <img
                          src="/document.svg"
                          alt="document"
                          width="33.33px"
                          height="33.33px"
                        />
                      </div>
                    </div>
                    <p className="p-published text-center">
                      {t("profile.noPredictionYetProfile")}
                    </p>
                    <div className="d-flex justify-content-center add-prediction-btn-div">
                      <button
                        className="btn-add-prediction"
                        onClick={handlePredictionShow}
                      >
                        {t("profile.addPredictions")}
                      </button>
                    </div>
                  </div>
                </div>

                {!isTablet && <UserRewardsSection />}
              </div>
            )}
            {showPredictionLoader ? (
              <div className="">
                <div className="mobile-predictionlistLoader mt-3">
                  <MobilePredictionListLoader />
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
          {/* analytics-tab-data  */}
          <div
            style={{ display: activeTab === "analytics" ? "block" : "none" }}
          >
            <div className="analyticsData-type">
              <ChartSummary id={loginUserId} />
            </div>
          </div>

          {isTablet && (
            <div style={{ display: activeTab === "reward" ? "block" : "none" }}>
              <UserRewardsSection />
            </div>
          )}

          {/* mobile add prediction Data */}
          <div className="mobile-prediction-button">
            <div
              style={{
                display: activeTab === "predictions" ? "block" : "none",
              }}
            >
              <div
                className="userProfile-mobile-add-prediction-button"
                style={{
                  display: betsDataCount > 0 ? "flex" : "none",
                }}
              >
                <button
                  className="btn-add-prediction prediction-list-top-btn"
                  onClick={handlePredictionShow}
                >
                  {t("profile.addPredictions")}
                </button>
              </div>
            </div>
          </div>
        </section>

        {/* prediction */}
        <Model
          isModel={predictionModel}
          modelClassName={"add-predication-model"}
          hideModel={handlePredictionHide}
          modelTitle={modelLabel}
          modelSkipSport={hadleSelectSportsBtn}
        >
          <div className="sports-leagues-country-container">
            {/* sports-list */}
            <div className={sportContains}>
              <SearchBar
                inputPlaceHolder={t("profile.search")}
                onChange={handleSportSearch}
              />
              {showSportLoader ? (
                <SportLeagueModelLoader />
              ) : (
                <div className="sports-scroll">
                  <div className="sports-main-container">
                    {sportData?.map((item, index) => (
                      <div
                        className="sports-containter"
                        key={index}
                        onClick={() =>
                          handlesports(item.id, item.name, item.name_ua)
                        }
                      >
                        <div className="d-flex">
                          <div className="sports-icon">
                            <img src={"sport-logo/" + item.id + ".svg"} />
                          </div>
                          <div className="sports-name">
                            {apiService.getProfileFromStorage()?.Language ==
                            "ua"
                              ? item.name_ua
                                ? item.name_ua
                                : item.name
                              : item.name}
                          </div>
                        </div>
                        <div className="sports-toggle-btn">
                          <img src="arrow-Right.svg" />
                        </div>
                      </div>
                    ))}
                    <div className={emptyMsgShow}>
                      No results that match your criteria
                    </div>
                  </div>
                </div>
              )}
            </div>

            {/* leaguees - country container  */}
            <div className={leagueesContains}>
              <SearchBar
                inputPlaceHolder={t("profile.search")}
                onChange={handleLeagueSearch}
              />
              {showLeaugeLoader ? (
                <div className="league-model-loader">
                  <SportLeagueModelLoader />
                </div>
              ) : (
                <div className="leagues-country-scroll pb-3">
                  <div className="leagues-all-leagues-container">
                    <div className={popularLeaguesHideShow}>
                      <div className="all-leagues">
                        <div className="all-leagues-containts">
                          {t("profile.allLeagues")}
                        </div>
                        <InputCheckbox
                          onChange={handleAllCheckBox}
                          id={"selectAll"}
                          isChecked={isCheckAll}
                          checkBoxName={"selectAll"}
                        />
                      </div>
                      {/* leagues-list */}
                      <div className="popular-league-section">
                        {popularLeagues.length > 0 ? (
                          <div>
                            <div className="popular-leagues-title">
                              {t("profile.popularLeagues")}
                            </div>
                            {popularLeagues.map((item, index) => (
                              <div className="popular-leagues" key={index}>
                                <div className="d-flex">
                                  <div className="leagues-image">
                                    <img
                                      src={`/country-logo/${item.country_id}.png`}
                                    />
                                  </div>
                                  <div className="leagues-name">
                                    {apiService.getProfileFromStorage()
                                      ?.Language == "en"
                                      ? item.name_en
                                        ? item.name_en
                                        : item.name
                                      : item.name}
                                  </div>
                                </div>
                                <InputCheckbox
                                  onChange={handleCheckBox}
                                  id={item.id}
                                  checkBoxName={item.name}
                                  isChecked={isCheck.includes(
                                    item.id.toString()
                                  )}
                                />
                              </div>
                            ))}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="model-country-title">
                        {t("profile.countries")}
                      </div>
                    </div>
                    {/* country-list */}
                    <div className="accordion-country-container">
                      <Accordion>
                        {countryLeaguesData.map((item, index) =>
                          item.id ? (
                            <Accordion.Item
                              eventKey={"countryId-" + item.id}
                              key={index}
                            >
                              <Accordion.Header>
                                <div className="d-flex">
                                  <div className="model-country-icon">
                                    <img
                                      src={"country-logo/" + item.id + ".png"}
                                    />
                                  </div>
                                  <div className="model-country-name">
                                    {apiService.getProfileFromStorage()
                                      ?.Language == "en"
                                      ? item.name_en
                                        ? item.name_en
                                        : item.name
                                      : item.name}
                                  </div>
                                </div>
                              </Accordion.Header>
                              <Accordion.Body>
                                {item.leagues.map((data, index) => (
                                  <div
                                    className=""
                                    id="countryDropDownId"
                                    key={index}
                                  >
                                    <div className="country-leaguees-containts">
                                      <div className="country-leaguees-name">
                                        {apiService.getProfileFromStorage()
                                          ?.Language == "en"
                                          ? data.name_en
                                            ? data.name_en
                                            : data.name
                                          : data.name}
                                      </div>
                                      <InputCheckbox
                                        onChange={handleCountryCheckbox}
                                        id={data.id}
                                        checkBoxName={data.name}
                                        isChecked={isCountryCheck.includes(
                                          data.id.toString()
                                        )}
                                      />
                                    </div>
                                  </div>
                                ))}
                              </Accordion.Body>
                            </Accordion.Item>
                          ) : (
                            ""
                          )
                        )}
                      </Accordion>
                    </div>
                    <div className={leagueEmptyMsgShow}>
                      No results that match your criteria
                    </div>
                  </div>
                </div>
              )}
              <div className="model-footer-part">
                <div className="selectSport-button-div">
                  {selectedButton ? (
                    <InputButton
                      type="icon-button"
                      onClick={hadleSelectSportsBtn}
                      inputLabel={t("profile.selectSport")}
                      addSpan={
                        <span>
                          <img src="arrow-Left.svg" />
                        </span>
                      }
                    />
                  ) : (
                    ""
                  )}
                </div>
                <InputButton
                  type="primary"
                  inputLabel={t("profile.showOddsLine")}
                  addBtnClass={"show-odds-line"}
                  disabled={
                    isCheck.length <= 0 &&
                    isCountryCheck.length <= 0 &&
                    !isCheckAll
                      ? true
                      : false
                  }
                  onClick={handleAddPrediction}
                />
              </div>
            </div>
          </div>
        </Model>

        {/* add-some-prediction-model */}
        <div className={addSomePredictionsModel}>
          <div className="prediction-list-model-body">
            {addPredictionsModel ? (
              <AddPrediction
                isModel={addPredictionsModel}
                increamentUserCount={increamentUserCount}
                getSportData={getSportData}
                editLeaugeModel={handleLeagueDataShow}
                hideModel={handleAddPredictionHide}
                sportId={sportId}
                addSportShowModel={handleSportShowModel}
                array={[...isCheck, ...isCountryCheck]}
              />
            ) : (
              ""
            )}
          </div>
        </div>

        {/* Edit profile model */}
        <Modal show={modelShow} onHide={handleClose}>
          <Modal.Header>
            <Modal.Title className="model-title">
              {t("profile.editProfile")}
            </Modal.Title>
            <div className="model-close-btn" onClick={handleClose}>
              <span>
                {" "}
                <img
                  src="close-squere-btn.svg"
                  alt="close"
                  className="close-button"
                />
              </span>
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className="edit-profile-content">
              <div className="avatar-section d-flex">
                <Avatar
                  editProfile={editProfile}
                  updateProfile={updateProfile}
                  profilePicVaildation={profilePicVaildation}
                  handleEditProfileDeletebutton={handleEditProfileDeletebutton}
                ></Avatar>
                <div className="d-flex img-validation justify-content-center">
                  <div className={typeError}>{t("error.avatarContent")}</div>
                  <div className={error}> {t("error.avatarSize")}</div>
                  {/* {userProfile.profileImgUrl == "" &&
                  hideDeleteButton == true ? (
                    <div className={introLine}>
                      JPG or PNG. Max size of 4MB.
                    </div>
                  ) : (
                    ""
                  )} */}
                  {userProfile?.profileImgUrl !== "" ||
                  editProfile.profileImgUrl !== "" ||
                  hideDeleteButton == false ? (
                    <button
                      className={showDeleteBtn}
                      onClick={btnDeletePicture}
                    >
                      {t("error.avatarDeleteBtn")}
                    </button>
                  ) : (
                    ""
                  )}
                  <div className={introLine}>{t("error.avatarDefault")}</div>
                  <div className={conformation}>
                    <div className="conformation-detail">
                      {t("error.avatarDeleteMsg")}
                    </div>
                    <div>
                      <button
                        className="btn-yes btn-img-delete"
                        onClick={deleteProfilePicture}
                      >
                        {t("error.avatarDeleteConfirm")}
                      </button>
                      <button
                        className=" btn-img-delete"
                        onClick={noDeleteProfilePicture}
                      >
                        {t("error.avatarDeleteDiscard")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="edit-profile-bio">
                <div className="bio-info">
                  <div className="bio-title">{t("profile.bio")}</div>
                  <div className="bio-count">{charCount}</div>
                </div>
                <form>
                  <textarea
                    name="bio"
                    cols="40"
                    maxLength={160}
                    className="form-textarea"
                    onChange={handlleBioCount}
                    //   (e) =>
                    //   setEditProfile({ ...editProfile, bio: e.target.value })
                    // }
                    onKeyUp={(e) => setCharCount(160 - e.target.value.length)}
                    defaultValue={userProfile?.bio}
                    placeholder={t("profile.textAreaPlaceHolder")}
                  ></textarea>
                </form>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button className="btn-cancel" onClick={handleClose}>
              {t("profile.saveCancel")}
            </Button>
            <Button
              disabled={
                error == "img-size-error validation-error-show" ||
                typeError == "img-size-error validation-error-show"
              }
              className="btn-saveprofile form-input-field-button"
              onClick={handleUpdateProfile}
            >
              {t("profile.saveConfirm")}
            </Button>
          </Modal.Footer>
        </Modal>

        {/* follower model */}
        <FollowingModal
          loginUserId={loginUserId}
          items={follow}
          showModal={showFollowerModel}
          handleHide={handlefollowerHide}
          handleUnFollow={handleUnFollow}
          handleUserProfile={handleUserProfile}
          handleFollow={handlefollow}
          isFollower={true}
        />

        {/* following model */}
        <FollowingModal
          loginUserId={loginUserId}
          items={following}
          showModal={showFollowingModel}
          handleHide={handlefollowingHide}
          handleUnFollow={handleUnFollow}
          handleUserProfile={handleUserProfile}
        />

        {/* share profile model */}
        <Modal
          show={showShareProfileModel}
          onHide={handleShareProfileHide}
          className="shareprofile-model"
        >
          <Modal.Header>
            <Modal.Title className="model-title">
              {t("profile.shareProfile")}
            </Modal.Title>
            <div className="model-close-btn" onClick={handleShareProfileHide}>
              <span>
                {" "}
                <img
                  src="close-squere-btn.svg"
                  alt="close"
                  className="close-button close-squere-button"
                />
              </span>
            </div>
          </Modal.Header>
          <ShareModel isUserName={userProfile._id} />
        </Modal>
      </div>
      <HomePageFooter />
    </>
  );
};
export default UserProfile;
