import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import { FaRegCircleQuestion } from "react-icons/fa6";

import NavigationBar from "../Navbar";
import HomePageHeader from "../molecule/homepage/HomePageHeader/HomePageHeader";
import ToolTip from "../General/ToolTip/ToolTip";
import HorizontalChart from "../General/HorizontalChart/HorizontalChart";

import apiService from "../../services/apiService";

import "./GameDetail.css";
import { formatTeamLinkIcon } from "../../helpers/formater";
import GameInfo from "./components/GameInfo/GameInfo";
import GameOverview from "./components/GameOverview/GameOverview";
import GamePredictions from "./components/GamePredictions/GamePredictions";
import HomePageFooter from "../molecule/homepage/HomePageFooter/HomePageFooter";

const GameDetail = () => {
  const { t, i18n } = useTranslation();
  const location = useLocation();

  const currentPath = location.pathname;
  const pathParts = currentPath.split("/");
  const gameId = pathParts[pathParts.length - 1];

  const [gameDetails, setGameDetails] = useState({});
  const [gameStatictic, setGameStatistic] = useState({});

  const getGameDetails = async () => {
    console.log(gameId);
    const res = await apiService.getGameDetails(gameId);
    if (res) {
      setGameDetails(res);
    }
    console.log(res);
  };

  const getGameStatistic = async () => {
    const res = await apiService.getGameStatistic(gameId);
    if (res) {
      setGameStatistic(res);
    }
    console.log(res);
  };

  useEffect(() => {
    getGameDetails();
    getGameStatistic();
  }, []);

  return (
    <div>
      <Helmet>
        <title>{t("Leaderboard.metaTitle")}</title>
        <meta name="description" content={t("Leaderboard.metaDescription")} />

        <meta name="og:title" content={t("Leaderboard.metaSocialTitle")} />
        <meta
          name="og:description"
          content={t("Leaderboard.metaDescription")}
        />
        <meta name="og:url" content="https://capperauditor.com/leaderboard" />
        <meta name="og:type" content="website" />

        <meta name="twitter:title" content={t("Leaderboard.metaSocialTitle")} />
        <meta
          name="twitter:description"
          content={t("Leaderboard.metaDescription")}
        />
        <meta
          name="twitter:image"
          content="https://capperauditor.com/preview-image.png"
        />
        <meta name="twitter:site" content="@Capperauditor" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      {localStorage.getItem("token") && localStorage.getItem("profile") ? (
        <NavigationBar countData={1} />
      ) : (
        <HomePageHeader />
      )}
      <div className="game-details__main-section">
        <div className="game-details__left-box">
          <GameInfo gameDetails={gameDetails} />
        </div>
        <div className="game-details__central-box">
          <div className="game-detail__tabs">
            <div className="game-detail__tab">{t("gameDetail.overView")}</div>
            <div className="game-detail__tab">
              {t("gameDetail.predictions")}
            </div>
            <div className="game-detail__tab">{t("gameDetail.analytics")}</div>
          </div>
          <div className="game-details__content">
            <div className="game-details__content-section">
              <div className="game-details__content-title">
                {t("gameDetail.overView")}
              </div>
              <div className="game-details__content-wrapper">
                <GameOverview gameStatictic={gameStatictic} />
              </div>
            </div>
            <div className="game-details__content-section">
              <div className="game-details__content-title">Прогнози</div>
              <div className="game-details__content-wrapper">
                <GamePredictions gameStatictic={gameStatictic} />
              </div>
            </div>
            <div className="game-details__content-section">
              <div className="game-details__content-title">
                {t("gameDetail.analytics")}
              </div>
              <div className="game-details__content-wrapper">
                <HorizontalChart />
              </div>
            </div>
          </div>
        </div>
        <div className="game-details__chat">
          <div className="game-details__chat-title">
            <img src="/gameDetail/chat.svg" alt="chat"></img>
            {t("gameDetail.chat")}
          </div>
          <div className="comming">Comming soon...</div>
        </div>
      </div>
      <HomePageFooter />
    </div>
  );
};

export default GameDetail;
