import React from "react";
import { Link } from "react-router-dom";
import "./homepageFooter.css";
import { useTranslation } from "react-i18next";
import apiService from "../../../../services/apiService";
const HomePageFooter = () => {
  const { t, i18n } = useTranslation();
  return (
    <>
      <div className="homePage-footer-main-div">
        <div className="HomePageFooter-gradient">
          <div className="HomePageFooter-container">
            <div className="footer-top-contains">
              <div className="footer-top-first-frame">
                <div>
                  <img src="/footer-logo.svg" />
                </div>
                <div className="first-frame-decription pb-4">
                  {t("homePage.homePageFooter.AllInOne")}
                </div>
                <div className="first-frame-2decription">
                  {t("homePage.homePageFooter.SecondDecription")}
                </div>
              </div>
              <div className="d-flex align-items-start footer-bottom-second-frame">
                <div className="footer-top-second-frame">
                  <div className="Navigation-title">
                    {t("homePage.homePageFooter.Navigation")}
                  </div>
                  <div className="second-frame-decription">
                    {localStorage.getItem("token") ? (
                      <Link
                        to="/userprofile"
                        className="feed-frame-decription pt-3"
                      >
                        {" "}
                        {t("navbar.profile")}
                      </Link>
                    ) : (
                      ""
                    )}
                    <Link
                      to={
                        localStorage.getItem("token") ? "/feed" : "/predictions"
                      }
                      className="feed-frame-decription pt-3"
                    >
                      {t("homePage.homePageFooter.Predictions")}
                    </Link>
                    <Link
                      to={
                        localStorage.getItem("language") === "ua"
                          ? "/sportline"
                          : "/en/sportline"
                      }
                      className="sportline-frame-decription pt-3"
                    >
                      {t("sportLine.sportlineTitle")}
                    </Link>
                    <Link
                      to={
                        localStorage.getItem("language") === "ua"
                          ? "/leaderboard"
                          : "/en/leaderboard"
                      }
                      className="leaderboard-frame-decription pt-3"
                    >
                      {t("navbar.Leaderboard")}
                    </Link>

                    <Link
                      to={
                        localStorage.getItem("language") === "ua"
                          ? "/marketplace"
                          : "/en/marketplace"
                      }
                      className="feed-frame-decription pt-3"
                    >
                      {t("navbar.Marketplace")}
                    </Link>
                    {localStorage.getItem("token") ? (
                      apiService.isAdmin() ? (
                        <Link
                          to={
                            localStorage.getItem("language") === "ua"
                              ? "/admin"
                              : "/en/admin"
                          }
                          className="sportline-frame-decription pt-3"
                        >
                          {t("navbar.AdminPanel")}
                        </Link>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="footer-top-third-frame">
                  <div className="Get-in-touch-title">
                    {t("homePage.homePageFooter.GetInTouch")}
                  </div>
                  <div className="third-frame-decription mt-3">
                    <a
                      href="mailto:support@capperauditor.com"
                      className="third-frame-decription"
                    >
                      support@capperauditor.com
                    </a>
                  </div>
                </div>
                <div className="footer-top-fourth-frame">
                  <div className="Follow-Us-title">
                    {t("homePage.homePageFooter.FollowUs")}
                  </div>
                  <div className="mt-3">
                    <a
                      href="http://instagram.com/capperauditor"
                      className="Instagram-icon Instagram-img"
                      target="_blank"
                    >
                      <svg
                        width="24"
                        height="25"
                        viewBox="0 0 24 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          className="Instagram-img-path"
                          d="M7.78216 3.98438H16.2169C19.165 3.98438 21 6.06556 21 9.01075V16.958C21 19.9032 19.165 21.9844 16.2159 21.9844H7.78216C4.83405 21.9844 3 19.9032 3 16.958V9.01075C3 6.06556 4.84281 3.98438 7.78216 3.98438Z"
                          stroke="#EEFBFB"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          className="Instagram-img-path"
                          d="M11.8445 9.81055C9.91023 9.81055 8.3418 11.379 8.3418 13.3133C8.3418 15.2475 9.91023 16.816 11.8445 16.816C13.7788 16.816 15.3472 15.2475 15.3472 13.3133C15.3472 11.379 13.7788 9.81055 11.8445 9.81055Z"
                          stroke="#EEFBFB"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          className="Instagram-img-path"
                          d="M16.8651 8.35007V8.41907V8.35007ZM17.1471 8.36509C17.1471 8.52209 17.0201 8.64908 16.8631 8.64908C16.7061 8.64908 16.5781 8.52209 16.5781 8.36509C16.5781 8.20809 16.7061 8.08008 16.8631 8.08008C17.0201 8.08008 17.1471 8.20809 17.1471 8.36509Z"
                          stroke="#EEFBFB"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      {/* <img src="/Instagram-white.svg" /> */}
                    </a>
                    {/* <a href="https://t.me/capper_auditor" target="_blank" className="telegram-img">
                      <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path className="telegram-img-path" d="M12.5131 16.9452L10.1876 18.931C9.77411 19.2832 9.13291 19.0624 9.02491 18.5302L8.13859 14.1693L3.8418 13.6244C2.86006 13.4998 2.68297 12.1532 3.59856 11.7786L19.7433 5.17101C20.4264 4.89177 21.1405 5.49794 20.9761 6.21698L17.8061 20.0937C17.632 20.8575 16.6843 21.13 16.1306 20.5763L10.8512 15.2979" stroke="#EEFBFB" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path className="telegram-img-path" d="M8.13867 14.1685L15.4293 9.25977" stroke="#EEFBFB" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                    </a> */}
                    <a
                      href="https://twitter.com/capper_auditor"
                      target="_blank"
                      className="Twitter-img"
                    >
                      <svg
                        width="24"
                        height="25"
                        viewBox="0 0 24 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          className="twitter-img-path"
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M19.4004 8.82459L21 6.40286L18.7194 6.74438C18.7194 6.74438 16.9631 4.38589 14.3867 5.16913C12.3006 5.80351 11.461 8.26416 11.6546 9.78492C8.74346 9.39475 6.04541 8.04621 3.98757 5.9514C2.91243 12.3507 5.01405 15.4564 8.16746 17.2243C6.65157 18.2693 4.84086 18.8015 3 18.7431C4.63751 20.7173 7.98649 21.0568 9.8293 20.9401C16.2568 20.5343 19.8305 15.4827 19.4257 9.05616L19.4004 8.82459Z"
                          stroke="#EEFBFB"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </a>

                    {/* <a
                      href="https://www.youtube.com/@capperauditor"
                      target="_blank"
                      className="Twitter-img"
                    >
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path className="twitter-img-path" d="M18.8056 5.12184C19.6812 5.37676 20.3584 6.09483 20.5793 7.00359C21.1417 8.68297 21.1388 15.3538 20.5793 16.9582C20.3584 17.867 19.6812 18.585 18.8056 18.84C17.2322 19.4286 6.76679 19.4101 5.19445 18.84C4.33627 18.5987 3.66394 17.9137 3.42069 17.0331C2.85928 15.3791 2.86026 8.68394 3.42069 7.04154C3.64156 6.1318 4.31779 5.41471 5.19445 5.15979C6.76679 4.58864 17.2322 4.58864 18.8056 5.12184Z" stroke="#EEFBFB" strokeWidth="1.5" strokeLinecap="round" strokeLinecap="round" />
                        <path className="twitter-img-path" d="M14.5191 12.4292L10.847 14.5173C10.5337 14.6953 10.1445 14.4696 10.1445 14.1086V9.93256C10.1445 9.57158 10.5337 9.34488 10.847 9.52294L14.5191 11.611C14.8363 11.7919 14.8363 12.2492 14.5191 12.4292Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinecap="round" />
                      </svg>
                    </a>
                    <a href=" https://www.twitch.tv/capperauditor" target="_blank" className="telegram-img">
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path className="telegram-img-path" fillRule="evenodd" clipRule="evenodd" d="M5.63984 3.5C4.55404 3.5 3.67383 4.38021 3.67383 5.46601V15.0648C3.67383 16.1506 4.55404 17.0308 5.63984 17.0308H5.81435C6.35725 17.0308 6.79736 17.4709 6.79736 18.0138V20.0075C6.79736 20.4454 7.32678 20.6647 7.63641 20.3551L10.3848 17.6066C10.7535 17.2379 11.2536 17.0308 11.775 17.0308H16.6944C17.2157 17.0308 17.7156 16.8238 18.0843 16.4552L19.751 14.7891C20.1199 14.4204 20.3271 13.9202 20.3271 13.3987V5.46601C20.3271 4.38021 19.4469 3.5 18.3611 3.5H5.63984Z" stroke="#EEFBFB" strokeWidth="1.5" strokeLinecap="round" strokeLinecap="round" />
                        <path className="telegram-img-path" d="M16.1643 8.19141V11.8267M12.001 8.19141V11.8267" stroke="#EEFBFB" strokeWidth="1.5" strokeLinecap="round" strokeLinecap="round" />
                      </svg>
                    </a>
                    <a
                      href="https://www.tiktok.com/@capperauditor"
                      target="_blank"
                      className="Tiktok-img"
                    >
                      <svg
                        width="24"
                        height="25"
                        viewBox="0 0 24 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          className="tiktok-img-path"
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M9.99022 10.3243C6.77065 10.3243 4.16016 12.928 4.16016 16.1543C4.16016 19.3807 6.77065 21.9844 9.99022 21.9844C13.2098 21.9844 15.8202 19.3807 15.8202 16.1543V9.67043C15.8202 9.67043 17.8985 11.1756 19.8376 10.657V7.41703C17.0549 7.15433 15.9526 4.88437 15.6529 3.98438H12.9402V16.0726C12.9402 17.5661 11.7318 18.7726 10.2412 18.7726C8.74968 18.7726 7.54124 17.5661 7.54124 16.0726C7.54124 14.6715 8.60373 13.5244 9.96492 13.3862C9.96492 12.4969 9.99022 10.3243 9.99022 10.3243Z"
                          stroke="#EEFBFB"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </a> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="footer-border"></div>
            <div className="footer-copy-rights d-flex">
              <div className="Privacy">
                <a
                  href={
                    localStorage.getItem("language") === "ua"
                      ? "/privacypolicy"
                      : "/en/privacypolicy"
                  }
                  className="privacypolicy-link"
                >
                  {t("homePage.homePageFooter.PrivacyPolicy")}
                </a>
              </div>
              <div className="Cookies">
                <a
                  href={
                    localStorage.getItem("language") === "ua"
                      ? "/cookie"
                      : "/en/cookie"
                  }
                  className="Cookies-link"
                >
                  {t("homePage.homePageFooter.Cookies")}
                </a>
              </div>
              <div className="Reserved-label">
                © {new Date().getFullYear()} Capperauditor.com.{" "}
                {t("homePage.homePageFooter.AllRightsReserved")}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default HomePageFooter;
