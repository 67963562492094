import React from "react";
import { formatDate, formatTeamLinkIcon } from "../../../../helpers/formater";

import "./GameInfo.css";

const GameInfo = ({ gameDetails }) => {
  return (
    <>
      <div className="game-details__game-info">
        <div className="game-details__controls">
          <div className="back__btn">
            <img src="/gameDetail/back.svg" alt="back" />
          </div>
          <div className="copy-link__btn">
            <img src="/gameDetail/link.svg" alt="link" />
          </div>
        </div>
        <div className="game-details__teams-info">
          <div className="game-details__teams-imgs">
            <div className="game-details__team-img-wrapper team1-img-wrapper">
              {gameDetails.opp_1_icon && (
                <img
                  className="game-details__team-logo"
                  src={formatTeamLinkIcon(gameDetails?.opp_1_icon)}
                  alt="team1"
                />
              )}
            </div>
            <div className="game-details__team-img-wrapper team2-img-wrapper">
              {gameDetails.opp_2_icon && (
                <img
                  className="game-details__team-logo"
                  src={formatTeamLinkIcon(gameDetails?.opp_2_icon)}
                  alt="team1"
                />
              )}
            </div>
          </div>
          <div className="game-details__teams-name">
            <div className="game-details__team-name-wrapper">
              <div className="game-details__team-name">
                {localStorage.getItem("language") === "ua"
                  ? gameDetails.opp_1_name
                  : gameDetails.opp_1_name_en}
              </div>
              <div className="game-details__team-score">1</div>
            </div>
            <div className="game-details__team-name-wrapper">
              <div className="game-details__team-name">
                {localStorage.getItem("language") === "ua"
                  ? gameDetails.opp_2_name
                  : gameDetails.opp_2_name_en}
              </div>
              <div className="game-details__team-score">2</div>
            </div>
          </div>
          <div className="game-details__info-about">
            <div className="game-details__info-about-item">
              <img
                className="info-about-item__img"
                src={"/sport-logo/" + gameDetails.sport_id + ".svg"}
                alt="icon"
              />
              <div className="info-about-item__text">
                {localStorage.getItem("language") === "ua"
                  ? gameDetails.sport_name + " "
                  : gameDetails.sport_name_en + " "}
                {localStorage.getItem("language") === "ua"
                  ? gameDetails.tournament_name
                  : gameDetails.tournament_name_en}
              </div>
            </div>
            <div className="game-details__info-about-item">
              <img
                className="info-about-item__img"
                src="/gameDetail/calendar.svg"
                alt="icon"
              />
              <div className="info-about-item__text">
                {formatDate(gameDetails.game_start, localStorage.getItem('language'))}
              </div>
            </div>
            <div className="game-details__info-about-item">
              <img
                className="info-about-item__img"
                src="/gameDetail/status.svg"
                alt="icon"
              />
              <div className="info-about-item__text">В очікуванні</div>
            </div>
          </div>
          <a
            className="make-prediction__link"
            href={
              localStorage.getItem("language") === "ua"
                ? "/sportline"
                : "/en/sportline"
            }
          >
            Зробити прогноз
          </a>
        </div>
      </div>
    </>
  );
};

export default GameInfo;
