import React from "react";
import { useTranslation } from "react-i18next";
import { FaRegCircleQuestion } from "react-icons/fa6";

import ToolTip from "../../../General/ToolTip/ToolTip";

const GameOverview = ({ gameStatictic }) => {
  const { t, i18n } = useTranslation();

  return (
    <div className="game-details__overview">
      <div className="game-details__overview-item">
        <ToolTip text={t("gameDetail.totalPredictionsHint")}>
          <div className="overview-item__title">
            {t("gameDetail.predictions")}
            <FaRegCircleQuestion />
          </div>
        </ToolTip>
        <div className="overview-item__info">
          {Object.keys(gameStatictic).length !== 0 ? (
            gameStatictic?.counts?.total
          ) : (
            <div className="skeleton-placeholder-light game-details_count-loader"></div>
          )}
        </div>
      </div>
      <div className="game-details__overview-item">
        <ToolTip text={t("gameDetail.avgCoefHint")}>
          <div className="overview-item__title">
            {t("gameDetail.avgCoef")}
            <FaRegCircleQuestion />
          </div>
        </ToolTip>
        <div className="overview-item__info">
          {Object.keys(gameStatictic).length !== 0 ? (
            gameStatictic?.avgCoef
          ) : (
            <div className="skeleton-placeholder-light game-details_count-loader"></div>
          )}
        </div>
      </div>
      <div className="game-details__overview-item">
        <ToolTip text={t("gameDetail.avgPriceHint")}>
          <div className="overview-item__title">
            {t("gameDetail.avgPrice")}
            <FaRegCircleQuestion />
          </div>
        </ToolTip>
        <div className="overview-item__info">
          {Object.keys(gameStatictic).length !== 0 ? (
            gameStatictic?.avgCoins + " CAP"
          ) : (
            <div className="skeleton-placeholder-light game-details_coins-count-loader"></div>
          )}
        </div>
      </div>
    </div>
  );
};

export default GameOverview;
